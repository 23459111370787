<template>
    <div class="s-languages">
        <div class="s-languages_inner" v-inview="{ start: 'center bottom' }">
            <div
                v-for="(col, i) in languages"
                :key="`languages-col-${i}`"
                class="s-languages_col"
            >
                <span
                    v-for="(lang, j) in col"
                    :key="`languages-col-${j}-lang-${j}`"
                    class="s-languages_item"
                >
                    {{ lang }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>

import { languages }      from '@/data'

export default {
    name: 'Languages',
    computed: {
        languages() {
            const rows = 9

            return [
                languages.slice().splice(0, rows),
                languages.slice().splice(rows, rows),
                languages.slice().splice(2 * rows, rows),
                [...languages.slice().splice(3 * rows, rows), '(And more)']
            ]
        }
    }
}

</script>

<style lang="scss">

.s-languages {
    margin-top: vh(10);
    margin-bottom: vh(20);
    padding-top: vh(15);
    font-family: var(--font-family-text);
    text-align: center;

    @media (max-width: $to-small) {
        width: 100%;
        overflow-x: auto;

        // Hide scrollbar
        &::-webkit-scrollbar {display: none;}
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
}

.s-languages_inner {
    display: grid;
    grid-column-gap: var(--grid-gutter);

    @media (max-width: $to-small) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (min-width: $from-small) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}

.s-languages_col {
    display: flex;
    flex-direction: column;

    &:last-child .s-languages_item:last-child {
        margin-top: auto;
    }

    @media (max-width: $to-small) {

        &:nth-child(1) {
            grid-row-start: 1;
        }

        &:nth-child(2) {
            grid-row-start: 2;
        }

        &:nth-child(3) {
            grid-row-start: 1;
        }
    }

    //     &:after {
    //         content: "(And more)";
    //         margin-top: auto;
    //         padding-top: .4em;
    //         padding-bottom: .4em;
    //     }
    // }
}

.s-languages_item {
    display: block;
    padding-top: .4em;
    padding-bottom: .4em;

    .s-languages_inner.is-inview & {
        transition: opacity $speed-slow $easing var(--languages-item-delay), transform $speed-slow $easing var(--languages-item-delay);
    }

    .s-languages_inner:not(.is-inview) & {
        opacity: 0;
        transform: translate(0, 50%);
    }

    @for $i from 1 through 9 {

        &:nth-child(#{$i}) {
            --languages-item-delay: #{($i - 1)/(2*9)}s;
        }
    }
}

</style>
