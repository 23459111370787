<template>
   <div class="s-playground | o-container">
        <div class="s-playground_inner">
            <div class="s-playground_controls">
                <div class="c-form_item -fonts">
                    <div class="s-playground_label">
                        <span>Font</span>
                    </div>

                    <dropdown
                        :options="FONT_FAMILIES_ARRAY"
                        @change="updateFont"
                        class="u-hide@from-small"
                    />

                    <div class="s-playground_fonts | u-hide@to-small">
                        <app-button
                            v-for="(f, i) in FONT_FAMILIES_ARRAY"
                            :key="`playground-font-${i}`"
                            class="c-button"
                            :class="{ 'is-active' : font.KEY === f.KEY}"
                            tag="button"
                            :border="true"
                            :stretch="true"
                            :label="f.NAME"
                            @click="updateFont(f)"
                        />
                    </div>
                </div>

                <div class="c-form_item">
                    <label
                        for="playground-size"
                        class="s-playground_label"
                    >
                        <span>Size</span>
                        <span>{{ fontSize }}pt</span>
                    </label>
                    <div class="s-playground_range">
                        <input
                            id="playground-size"
                            type="range"
                            min="10"
                            :max="maxFontSize"
                            v-model="fontSize"
                            class="c-form_range"
                        >
                    </div>
                </div>

                <div class="c-form_item">
                    <label
                        for="playground-weight"
                        class="s-playground_label"
                    >
                        <span>Weight</span>
                        <span>{{ fontWeight }}</span>
                    </label>
                    <div class="s-playground_range">
                        <input
                            id="playground-weight"
                            type="range"
                            :min="fontWeightRange[0]"
                            :max="fontWeightRange[1]"
                            v-model="fontWeight"
                            class="c-form_range"
                        >
                    </div>
                </div>

                <div class="c-form_item">
                    <label
                        for="playground-spacing"
                        class="s-playground_label"
                    >
                        <span>Letter Spacing</span>
                        <span>{{ letterSpacing }}%</span>
                    </label>
                    <div class="s-playground_range">
                        <input
                            id="playground-spacing"
                            type="range"
                            min='-10'
                            max="10"
                            v-model="letterSpacing"
                            class="c-form_range"
                        >
                    </div>
                </div>

                <div class="c-form_item">
                    <label
                        for="playground-height"
                        class="s-playground_label"
                    >
                        <span>Line Height</span>
                        <span>{{ Math.round(lineHeight/fontSize * fontSize) }}pt</span>
                    </label>
                    <div class="s-playground_range">
                        <input
                            id="playground-height"
                            type="range"
                            :min="fontSize"
                            :max="2 * fontSize"
                            v-model="lineHeight"
                            class="c-form_range"
                        >
                    </div>
                </div>
            </div>

            <div class="s-playground_main">
                <label for="playground-textarea">
                    Type in some text
                </label>
                <textarea
                    id="playground-textarea"
                    class="s-playground_textarea"
                >Sphinx of Black Quartz, Judge My Vow</textarea>
            </div>
        </div>
   </div>
</template>

<script>

import Dropdown                                 from '@/templates/objects/Dropdown'
import AppButton                                from '@/templates/components/AppButton'
import { clamp }                                from '@/utils/maths'
import { CUSTOM_EVENTS, FONT_FAMILIES_ARRAY}    from '@/constants'

const breakpoint = 1600
const fontSizeRange = [10, 500]

export default {
    name: 'Playground',
    components: {
        Dropdown,
        AppButton,
    },
    data: () => ({
        FONT_FAMILIES_ARRAY,
        letterSpacing: 0,
        lineHeight: 0,
        maxLineHeight: null,
        font: {},
        fontFamily: 'inherit',
        fontSize: 0,
        maxFontSize: null,
        fontWeight: 'inherit',
        fontWeightRange: [100, 900]
    }),
    created() {
        this.setRanges()
    },
    mounted() {
        window.addEventListener(CUSTOM_EVENTS.RESIZE_END, () => this.setRanges())
    },
    methods: {
        setRanges() {
            this.maxFontSize = Math.round(clamp(fontSizeRange[0], (fontSizeRange[1]/breakpoint) * window.innerWidth, fontSizeRange[1]))
            this.fontSize = Math.round(this.maxFontSize/2)
        },
        updateFont(e) {
            this.font = e
            this.fontFamily = e.CSS_VAR
            this.fontWeightRange = e.WEIGHT_RANGE
            this.fontWeight = 400
        }
    },
    watch: {
        fontSize(size) {

            this.$nextTick(() => {
                this.lineHeight = Math.round(1.25 * size)
            })
        }
    }
}

</script>

<style lang="scss">

.s-playground {
    --playground-letter-spacing: v-bind(letterSpacing);
    --playground-line-height: v-bind(lineHeight);
    --playground-font-size: v-bind(fontSize);

    z-index: z("above");
    padding-top: vh(20);
    margin-bottom: vh(20);
    background-color: var(--color-bg);
}

.s-playground_inner {
}

.s-playground_controls {
    z-index: z("above");
    display: grid;
    grid-gap: rem(32px) var(--grid-gutter);

    @media (min-width: $from-small) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}

.c-form_item {

    @media (min-width: $from-small) {

        &.-fonts {
            grid-column: -1/1;
        }
    }
}

.s-playground_label {
    display: flex;
    justify-content: space-between;
    margin-bottom: rem(12px);
    font-size: rem(14px);

    span {

        &:nth-child(1) {
            opacity: .6;
        }
    }
}

.s-playground_range {
    $thumb-width: rem(24px);

    display: block;
    width: 100%;
    margin-top: rem(40px);

    &:before {
        content: "";
        position: absolute;
        bottom: 0.5 * $thumb-width;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: currentColor;
    }
}

.s-playground_fonts {
    display: grid;
    grid-gap: var(--grid-gutter);
    grid-template-columns: repeat(4, minmax(0, 1fr));

    .c-button {

        &.is-active {
            pointer-events: none;
        }

        &:not(.is-active):not(:hover) {
            opacity: .6;
        }
    }
}

.s-playground_main {

    label {
        @include u-accessibly-hidden;
    }
}

.s-playground_textarea {
    width: 100%;
    // height: 70vmin;
    min-height: calc(#{rem(60px)} + 3px * var(--playground-line-height));
    margin-top: vh(10);
    padding-top: rem(60px);

    font-family: v-bind(fontFamily);
    font-variation-settings: "wght" v-bind(fontWeight);

    letter-spacing: calc(1px * var(--playground-letter-spacing));
    line-height: calc(var(--playground-line-height)/var(--playground-font-size));
    font-size: calc(1px * var(--playground-font-size));

    color: inherit;
    text-align: center;
    background-color: transparent;
    border: 0;
    resize: none;
    overflow: hidden;
}

</style>
