<template>

    <!-- Grid debugger for dev -->
    <grid-debugger v-if="IS_DEV" />

    <!-- First loader -->
    <loader />

    <!-- Header -->
    <app-header />

    <!-- View component -->
    <component :is="currentView" />

    <!-- Footer -->
    <app-footer />

    <!-- Custom cursor -->
    <app-cursor />

</template>

<script>

import GridDebugger                                                     from '@/templates/objects/GridDebugger'
import AppCursor                                                        from '@/templates/objects/AppCursor'
import Loader                                                           from '@/templates/objects/Loader'
import AppHeader                                                        from '@/templates/components/AppHeader'
import AppFooter                                                        from '@/templates/components/AppFooter'
import Home                                                             from '@/templates/views/Home'
import Error404                                                         from '@/templates/views/Error404'

import { CSS_CLASS, CUSTOM_EVENTS, IS_DEV, IS_MOBILE, PRERENDERING }    from '@/constants'
import { vh }                                                           from '@/utils/vh'
// import { preloadImages }                                                from '@/utils/images'

import Lenis                                                            from '@studio-freight/lenis'

const $html = document.documentElement


export default {
    name: 'App',
    components: {
        GridDebugger,
        AppCursor,
        Loader,
        AppHeader,
        AppFooter,
        Home,
    },
    data: () => ({
        IS_DEV,
        currentTitleIndex: 0,
        titles: [
            'Fragment | Pangram Pangram® Foundry + Locomotive®',
            'Ḟrågḿěnŧ | Pangram Pangram® Foundry + Locomotive®',
            'Fŕaǥmeńť | Pangram Pangram® Foundry + Locomotive®',
            'Ḟrägṁėņt | Pangram Pangram® Foundry + Locomotive®',
            'Fřaġmeňţ | Pangram Pangram® Foundry + Locomotive®',
        ]
    }),
    created() {
        if(!PRERENDERING) {
            // Set initial VH var for mobile
            if(IS_MOBILE) {
                $html.style.setProperty('--vh', `${vh(1)}px`)
                $html.classList.add(CSS_CLASS.MOBILE)

                // Reload page on orientation change
                const portrait = window.matchMedia('(orientation: portrait)')
                portrait.addEventListener('change', () => location.reload())

            } else {
                $html.classList.add(CSS_CLASS.DESKTOP)
            }
        }

        this.setTitle()
    },
    mounted() {

        // Smooth scrolling initialization (using Lenis https://github.com/studio-freight/lenis)
        this.lenis = new Lenis({
            lerp: 0.1,
            smooth: true,
            duration: 1
        })

        const scrollFn = t => {
            this.lenis.raf(t);
            requestAnimationFrame(scrollFn);
        }

        requestAnimationFrame(scrollFn);

        // Anchor links
        document.querySelectorAll('a[href^="#"]').forEach($anchor => {
            $anchor.addEventListener('click', () => {
                this.scrollTo($anchor.getAttribute('href'))
            })
        })

        // All fonts load
        const fontLoad = document.fonts.ready.then(() => {
            const fontsLoadedEvent = new CustomEvent(CUSTOM_EVENTS.FONTS_LOADED)
            window.dispatchEvent(fontsLoadedEvent)
        })

        // // Images preload
        // const imagesPreload = new Promise((resolve) => {
        //     preloadImages(resolve)
        // })

        // First load delay
        const firstLoad = new Promise(resolve => {

            const duration = IS_DEV ? 2000 : 4000
            let delay = new Date()
            delay = (delay - window.timestamp)
            delay = delay > duration ? 0 : duration - delay

            setTimeout(() => {
                $html.classList.remove(CSS_CLASS.IS_LOADING)

                setTimeout(() => {
                    resolve()
                }, 2500)
            }, delay)

        })

        // First animation
        const firstAnimation = new Promise(resolve => {
            resolve()
        })

        const hash = window.location.hash
        Promise.all([fontLoad, firstLoad]).then(() => {

            firstAnimation.then(() => {
                const readyEvent = new CustomEvent(CUSTOM_EVENTS.READY)
                window.dispatchEvent(readyEvent)
                $html.classList.add(CSS_CLASS.IS_READY)

                if(hash) {
                    this.scrollTo(hash)
                }
            })
        })
    },
    computed: {
        currentView() {
            return window.location.pathname === '/' ? Home : Error404
        },
        titlesCount() {
            return this.titles.length
        }
    },
    methods: {
        setTitle() {
            this.currentTitleIndex = (this.currentTitleIndex + 1) % this.titlesCount
            document.title = this.titles[this.currentTitleIndex]

            setTimeout(() => {
                this.setTitle()
            }, 750);
        },
        scrollTo(target) {
            this.lenis.scrollTo(target, {
                duration: 1,
                immediate: true // No smooth scrolling
            })
        }
    }
}

</script>

<style lang="scss">

</style>
