<template>
    <section class="s-hero" v-inview="{ start: 'top top', end: 'bottom+=50% top', onUpdate }">
        <overlap
            ref="bg"
            filename="fragment"
            :total="25"
            :preload="true"
            class="s-hero_overlap"
        />
        <div class="s-hero_inner | o-container">
            <header class="s-hero_header">
                <h2 class="s-hero_title">
                    <slot name="title">
                        <rolling-text
                            :visible="true"
                            v-bind="title1"
                        />
                        <rolling-text
                            :visible="true"
                            v-bind="title2"
                        />
                    </slot>
                </h2>
                <div class="s-hero_sub">
                    <slot name="sub">
                        <anim-text
                            class="o-text -sans"
                            text="Sans"
                            tag="span"
                            :visible="true"
                        />
                        <anim-text
                            class="o-text -glare"
                            text="Glare"
                            tag="span"
                            :visible="true"
                        />
                        <anim-text
                            class="o-text -serif"
                            text="Serif"
                            tag="span"
                            :visible="true"
                        />
                        <anim-text
                            class="o-text -text"
                            text="Text"
                            tag="span"
                            :visible="true"
                        />
                    </slot>
                </div>
            </header>
            <div class="s-hero_bottom">
                <anim-text
                    text="A New 【Free to Try】 Font <br> by Francesca Bolognini & Mathieu Desjardins"
                    :visible="true"
                />
                <anim-text
                    text="Available exclusively at <br> <a href='https://pangrampangram.com' target='_blank' rel='noopener noreferrer' class='o-link'>pangrampangram.com</a>"
                    :visible="true"
                />
            </div>
        </div>
    </section>
</template>

<script>

import AnimText                         from '@/templates/objects/AnimText'
import RollingText                      from '@/templates/objects/RollingText'
import Overlap                          from '@/templates/components/Overlap'
import { FONT_FAMILIES }                from '@/constants'
import { round }                        from '@/utils/maths'

export default {
    name: 'Hero',
    components: {
        AnimText,
        Overlap,
        RollingText,
    },
    data: () => ({
        title1: {
            text: 'PP',
            fonts: [ FONT_FAMILIES.SANS.KEY ],
            defaultFont: FONT_FAMILIES.SERIF.KEY,
            durationRange: [1.75, 2.5]
        },
        title2: {
            text: 'Fragment',
            fonts: [ FONT_FAMILIES.SANS.KEY, FONT_FAMILIES.SANS.KEY, FONT_FAMILIES.SANS.KEY, FONT_FAMILIES.SANS.KEY ],
            defaultFont: FONT_FAMILIES.SERIF.KEY,
            durationRange: [1.75, 2.5]
        },
        progress: 0,
    }),
    methods: {
        onUpdate({ progress }) {
            this.progress = round(progress, 3)
        }
    }
}

</script>

<style lang="scss">

.s-hero {
    --hero-progress: v-bind(progress);
}

.s-hero_overlap {
    --overlap-width: 60vw;
    --overlap-height: #{vh(50)};

    left: 0;
    width: 100%;
    height: 200%;

    // &:after {
    //     content: "";
    //     position: fixed;
    //     top: 0;
    //     left: 0;
    //     display: block;
    //     width: 100%;
    //     height: 100%;
    //     background-color: $color-darkest;
    //     opacity: 0;
    //     transition: opacity 1.2s ease-out .4s;

    //     html.is-loading & {
    //         opacity: 1;
    //     }

    //     html.is-ready & {
    //         opacity: .5;
    //         transition: opacity 1s ease-out;
    //     }
    // }

    .c-overlap_inner {
        top: vh(25);
        opacity: calc(1 - 1 * var(--hero-progress));
        transform: translate(0, calc(50% * var(--hero-progress))) scale(calc(1 + 0.3 * var(--hero-progress)));
        transform-origin: 50% 100%;

        html:not(.is-loading) & {
            transform: scale(1.1);
            transform-origin: 50% 50%;
            animation: anim-hero-scale .55s $ease-power2-in 2s forwards;
        }
    }

    .c-overlap_asset {
        transform: translate(var(--overlap-x, 0), var(--overlap-y, 0)) rotate(var(--overlap-rotate, 0));
        transition: transform 3s $ease-power3-out;
        will-change: opacity, transform;

        html:not(.is-loading) & {
            animation: anim-hero-opacity var(--overlap-opacity-duration) $ease-power2-in-out var(--overlap-opacity-delay) forwards;
        }

        html:not(.is-ready) & {
            opacity: 0;
        }

        html.is-ready & {
        }
    }

    .c-overlap_item {

        @for $i from 1 through 25 {

            &:nth-child(#{$i}) {
                --overlap-float-duration: calc(1s * (#{random(100)/50 + 4}));
                --overlap-opacity-duration: calc(2s * (#{random(20)/20} + 0.5));
                --overlap-opacity-delay: calc(1s * (#{random(100)/100}));
            }

        }

        html.is-ready & {
            animation: anim-hero-float var(--overlap-float-duration) $ease-sine-in-out infinite 1s;
        }

        html.is-ready & {

            // FRAG
            &:nth-child(1) {
                --overlap-x             : -50vw;
                --overlap-y             : #{vh(-40)};
                --overlap-rotate        : 10deg;
            }

            &:nth-child(2) {
                --overlap-x             : -50vw;
                --overlap-y             : #{vh(-20)};
                --overlap-rotate        : 45deg;
            }

            &:nth-child(3) {
                --overlap-x             : -20vw;
                --overlap-y             : #{vh(-50)};
                --overlap-rotate        : -45deg;
            }

            &:nth-child(4) {
                --overlap-x             : 20vw;
                --overlap-y             : #{vh(20)};
                --overlap-rotate        : -15deg;
            }

            &:nth-child(5) {
                --overlap-x             : 10vw;
                --overlap-y             : #{vh(10)};
                --overlap-rotate        : 45deg;
            }

            &:nth-child(6) {
                --overlap-x             : 30vw;
                --overlap-y             : #{vh(-40)};
                --overlap-rotate        : -25deg;
            }

            &:nth-child(7) {
                --overlap-x             : -20vw;
                --overlap-y             : #{vh(-50)};
                --overlap-rotate        : -30deg;
            }

            &:nth-child(8) {
                --overlap-x             : 40vw;
                --overlap-y             : #{vh(-10)};
                --overlap-rotate        : -35deg;
            }

            &:nth-child(9) {
                --overlap-x             : 50vw;
                --overlap-y             : #{vh(-90)};
                --overlap-rotate        : 45deg;
            }

            &:nth-child(10) {
                --overlap-x             : 60vw;
                --overlap-y             : #{vh(-20)};
                --overlap-rotate        : -30deg;
            }

            // MENT
            &:nth-child(11) {
                --overlap-x             : -30vw;
                --overlap-y             : #{vh(-20)};
                --overlap-rotate        : 25deg;
            }

            &:nth-child(12) {
                --overlap-x             : -20vw;
                --overlap-y             : #{vh(50)};
                --overlap-rotate        : 15deg;
            }

            &:nth-child(13) {
                --overlap-x             : -15vw;
                --overlap-y             : #{vh(30)};
                --overlap-rotate        : -30deg;
            }

            &:nth-child(14) {
                --overlap-x             : -5vw;
                --overlap-y             : #{vh(50)};
                --overlap-rotate        : -10deg;
            }

            &:nth-child(15) {
                --overlap-x             : -40vw;
                --overlap-y             : #{vh(20)};
                --overlap-rotate        : -20deg;
            }

            &:nth-child(16) {
                --overlap-x             : -10vw;
                --overlap-y             : #{vh(20)};
                --overlap-rotate        : 10deg;
            }

            &:nth-child(17) {
                --overlap-x             : 15vw;
                --overlap-y             : #{vh(-20)};
                --overlap-rotate        : -10deg;
            }

            &:nth-child(18) {
                --overlap-x             : 20vw;
                --overlap-y             : #{vh(50)};
                --overlap-rotate        : -30deg;
            }

            &:nth-child(19) {
                --overlap-x             : 5vw;
                --overlap-y             : #{vh(70)};
                --overlap-rotate        : -10deg;
            }

            &:nth-child(20) {
                --overlap-x             : -5vw;
                --overlap-y             : #{vh(40)};
                --overlap-rotate        : 25deg;
            }

            &:nth-child(21) {
                --overlap-x             : 40vw;
                --overlap-y             : #{vh(-15)};
                --overlap-rotate        : 15deg;
            }

            &:nth-child(22) {
                --overlap-x             : 20vw;
                --overlap-y             : #{vh(30)};
                --overlap-rotate        : -10deg;
            }

            &:nth-child(23) {
                --overlap-x             : 7vw;
                --overlap-y             : #{vh(20)};
                --overlap-rotate        : -5deg;
            }

            &:nth-child(24) {
                --overlap-x             : 40vw;
                --overlap-y             : #{vh(10)};
                --overlap-rotate        : -10deg;
            }

            &:nth-child(25) {
                --overlap-x             : -10vw;
                --overlap-y             : #{vh(50)};
                --overlap-rotate        : 10deg;
            }
        }
    }
}

.s-hero_inner {
    height: vh(100);
    display: flex;
    align-items: center;
    justify-content: center;
}

.s-hero_title {
    z-index: z("above"); // Fix safari glitch bug
    display: flex;
    flex-direction: column;
    font-size: 10vw;
    font-variation-settings: "wght" 300;
    letter-spacing: -.04em;
    line-height: .95;
    text-align: center;
    text-transform: uppercase;

    &:after,
    &:before {
        position: absolute;
        top: 4vw;
        font-size: rem(24px);
        letter-spacing: 0;
        transform: rotateY(90deg);
    }

    &:before {
        content: "②⓪";
        left: 15%;
    }

    &:after {
        content: "②②";
        right: 15%;
    }

    .o-rolling-text {

        // &:nth-child(1) {

        //     .o-rolling-text_item:nth-child(2) {
        //         padding-right: .05em;
        //     }
        // }

        &:nth-child(2) {
            --rolling-text-delay: .4s;
        }
    }

    html.is-ready & {

        &:after,
        &:before {
            transform: rotateY(0);
            transition: transform $speed-slow $easing 2s;
        }
    }
}

.s-hero_sub {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    font-size: rem(14px);
    font-variation-settings: "wght" 400;
    text-transform: uppercase;

    .o-at {

        &:nth-child(1) {
            --at-delay-in: 2s;
        }

        &:nth-child(2) {
            --at-delay-in: 2.1s;
        }

        &:nth-child(3) {
            --at-delay-in: 2.2s;
        }

        &:nth-child(4) {
            --at-delay-in: 2.3s;
        }
    }
}

.s-hero_bottom {
    position: absolute;
    bottom: var(--grid-gutter);
    right: var(--grid-gutter);
    left: var(--grid-gutter);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-variation-settings: "wght" 300;

    .o-at {
        --at-delay-in: 2.5s;
        width: 50%;
        column-gap: var(--grid-gutter);

        &:nth-child(2) {
            text-align: right;
        }
    }
}


// Animations
@keyframes anim-hero-scale {
    90% {
        transform: scale(.9);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes anim-hero-opacity {
    to {
        opacity: 1;
    }
}

@keyframes anim-hero-float {
    0% {
        transform: translate(0) rotate(0);
    }
    50% {
        transform: translate(0, 10%);
    }
    // 75% {
    //     transform: translate(0, 10%);
    // }
    100% {
        transform: translate(0);
    }
}

</style>
