<template>
    <div class="o-loader">
        <span class="o-loader_spinner">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M4.05,18.21l5.69-7.73L8.35,20h3.3l-1.42-9.52L16,18.21l2.32-2.32-7.61-5.64L20,11.66V8.36L10.64,9.78l7.63-5.67L16,1.79,10.23,9.52,11.65,0H8.35L9.74,9.52,4.05,1.79,1.73,4.11,9.36,9.78,0,8.36v3.3l9.34-1.41L1.73,15.89Z"/>
            </svg>
        </span>
    </div>
</template>

<script>

export default {
    name: 'Loader',
}

</script>

<style lang="scss">

.o-loader {
    z-index: 500;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color-darkest;

    html:not(.is-loading) & {
        left: 200vw;
        transition: left 0s .4s;
    }
}

.o-loader_spinner {
    position: absolute;
    right: var(--grid-gutter);
    bottom: var(--grid-gutter);
    display: block;

    svg {
        width: 2em;
        height: 2em;
    }

    html:not(.is-ready) & {
        animation: anim-loader-spinner 2s $ease-circ-in-out infinite;
    }

    html:not(.is-loading) & {

        svg {
            opacity: 0;
            transform: scale(.5);
            transition: all .4s $ease-circ-in;
        }
    }
}

// Animations
@keyframes anim-loader-spinner {
    to {
        transform: rotate(1turn);
    }
}

</style>
