<template>
   <div
        class="s-random | o-container o-sticky-section -desktop | u-color-invert"
        v-inview="{ start: 'top bottom', end: 'bottom center', onToggle }"
    >
    <div class="s-random_inner">
        <rail
            ref="rail"
            class="s-random_rail"
        />
        <app-button
            tag="button"
            label="Randomize"
            :border="true"
            class="s-random_button"
            @click="updateVariation()"
        />
        <div
            ref="content"
            class="s-random_content"
            :class="`-variation-${currentVariationIndex + 1}`"
        >
            <div
                v-for="(item, i) in items"
                :key="`random-item-${i}`"
                class="s-random_item"
            >
                <anim-text
                    tag="span"
                    :text="item"
                    type="chars"
                    class="s-random_text"
                />
                <span class="s-random_label">
                    <span class="s-random_tag">PP Fragment</span>
                    <span class="s-random_tag -font"></span>
                </span>
            </div>
        </div>
    </div>
   </div>
</template>

<script>

import Rail                             from '@/templates/objects/Rail'
import AnimText                         from '@/templates/objects/AnimText'
import AppButton                        from '@/templates/components/AppButton'

// import { FONT_FAMILIES, CUSTOM_EVENTS } from '@/constants'
// import { gsap }                         from '@/gsap'
// import { ScrollTrigger }                from '@/gsap'

// const fontWeights = {
//     100: 'Thin',
//     200: 'Extra Light',
//     300: 'Light',
//     400: 'Regular',
//     500: 'Medium',
//     600: 'Semi Bold',
//     700: 'Bold',
//     800: 'Extra Bold',
//     900: 'Black',
// }

export default {
    name: 'Random',
    components: {
        Rail,
        AnimText,
        AppButton
    },
    data: () => ({
        currentVariationIndex: 0,
        totalVariations: 4,
        items: [
            `① So`,
            `many things`,
            `②〖are possible〗`,
            `as long as`,
            `③ you`,
            `don't know`,
            `④ they`,
            `are impossible`,
        ],

    }),
    methods: {
        onToggle({isActive}) {
            if(isActive) {
                this.setCountdown()
            } else {
                clearInterval(this.countdown)
            }

            this.$refs.rail.toggle(isActive)
        },
        updateVariation() {
            this.currentVariationIndex = (this.currentVariationIndex + 1) % this.totalVariations
            this.setCountdown()
        },
        setCountdown() {
            if(this.countdown) {
                clearInterval(this.countdown)
            }

            this.countdown = setInterval(() => {
                this.updateVariation()
                clearInterval(this.countdown)
                this.setCountdown()
            }, 5000);
        }
    },
}

</script>

<style lang="scss">

.s-random {
    --sticky-section-height: #{vh(200)};
    // --sticky-section-offset: var(--grid-gutter);
    // --sticky-section-inner-height: var(--height-gutters);
    --random-text-font-size: #{rem(32px)};

    margin-bottom: vh(10);
    background-color: $color-darkest;

    @media (min-width: $from-tiny) {
        --random-text-font-size: #{responsive-type(36px, 92px, 1800)};
    }
}

.s-random_inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: rem(4px);
    padding-bottom: var(--grid-gutter);
    color: var(--color-text);
    background-color: var(--color-bg);
    border-radius: $border-radius;
    overflow: hidden;

    html.is-mobile & {
        height: vh(100);
    }
}

.s-random_rail {
    margin-bottom: var(--grid-gutter);
}

.s-random_button {
    display: flex;
    justify-content: center;
    width: grid-space(4/8);
    margin: auto;
    text-align: center;
}

.s-random_content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    font-size: var(--random-text-font-size);
    margin-top: var(--grid-gutter);
    padding-right: var(--grid-gutter);
    padding-left: var(--grid-gutter);
}

.s-random_item {
    font-family: var(--random-item-font-family);
    font-variation-settings: "wght" var(--random-item-font-weight, 400);
    transition: font-variation-settings $speed-med $ease-power2-out $speed-slow, transform $speed-slow $ease-power2-in;

    &:hover .s-random_tag {
        opacity: 1;
        transform: translate(0);
        transition-property: opacity, transform;
        transition-timing-function: $easing;
        transition-duration: $speed-slow;

        &.-font {
            transition-delay: .1s;
        }
    }

    @for $i from 1 through 8 {

        &:nth-child(#{$i}) .o-at {
            --at-delay-in: #{$i/8}s;
        }
    }

    &:nth-child(1),
    &:nth-child(2) {
        --random-item-font-family: var(--font-family-glare);

        .s-random_tag.-font:before {
            content: "Glare";
        }
    }

    &:nth-child(3),
    &:nth-child(4) {
        --random-item-font-family: var(--font-family-sans);

        .s-random_tag.-font:before {
            content: "Sans";
        }
    }

    &:nth-child(5),
    &:nth-child(6) {
        --random-item-font-family: var(--font-family-serif);

        .s-random_tag.-font:before {
            content: "Serif";
        }
    }

    &:nth-child(7),
    &:nth-child(8) {
        --random-item-font-family: var(--font-family-text);

        .s-random_tag.-font:before {
            content: "Text";
        }
    }

    &:nth-child(1) {

        .s-random_content.-variation-1 & {
            --random-item-font-weight: 400;
            --random-item-translation: 0;

            .s-random_tag.-font:after {
                content: "Regular";
            }
        }

        .s-random_content.-variation-2 & {
            --random-item-font-weight: 900;
            --random-item-translation: calc(#{grid-space(8/8, -2)} - 100%);

            .s-random_label {
                left: auto;
                right: 0;
            }

            .s-random_tag.-font:after {
                content: "Black";
            }
        }

        .s-random_content.-variation-3 & {
            --random-item-font-weight: 100;
            --random-item-translation: calc(#{grid-space(4/8, -1)} - 50%);

            .s-random_tag.-font:after {
                content: "Thin";
            }
        }

        .s-random_content.-variation-4 & {
            --random-item-font-weight: 200;
            --random-item-translation: #{grid-space(5/8)};

            .s-random_tag.-font:after {
                content: "Extra Light";
            }
        }
    }

    &:nth-child(2) {

        .s-random_content.-variation-1 & {
            --random-item-font-weight: 700;
            --random-item-translation: 0;

            .s-random_tag.-font:after {
                content: "Bold";
            }
        }

        .s-random_content.-variation-2 & {
            --random-item-font-weight: 800;
            --random-item-translation: calc(#{grid-space(8/8, -2)} - 100%);

            .s-random_tag.-font:after {
                content: "Extra Bold";
            }
        }

        .s-random_content.-variation-3 & {
            --random-item-font-weight: 200;
            --random-item-translation: #{grid-space(2/8)};

            .s-random_tag.-font:after {
                content: "Extra Light";
            }
        }

        .s-random_content.-variation-4 & {
            --random-item-font-weight: 100;
            --random-item-translation: 0;

            .s-random_tag.-font:after {
                content: "Thin";
            }
        }
    }

    &:nth-child(3) {

        .s-random_content.-variation-1 & {
            --random-item-font-weight: 700;
            --random-item-translation: calc(#{grid-space(4/8, -1)} - 50%);

            .s-random_tag.-font:after {
                content: "Bold";
            }
        }

        .s-random_content.-variation-2 & {
            --random-item-font-weight: 700;
            --random-item-translation: 0;

            .s-random_tag.-font:after {
                content: "Bold";
            }
        }

        .s-random_content.-variation-3 & {
            --random-item-font-weight: 300;
            --random-item-translation: calc(#{grid-space(8/8, -2)} - 100%);

            .s-random_tag.-font:after {
                content: "Light";
            }
        }

        .s-random_content.-variation-4 & {
            --random-item-font-weight: 300;
            --random-item-translation: calc(#{grid-space(4/8, -1)} - 50%);

            .s-random_tag.-font:after {
                content: "Light";
            }
        }
    }

    &:nth-child(4) {

        .s-random_content.-variation-1 & {
            --random-item-font-weight: 900;
            --random-item-translation: calc(#{grid-space(4/8, -1)} - 50%);

            .s-random_tag.-font:after {
                content: "Black";
            }
        }

        .s-random_content.-variation-2 & {
            --random-item-font-weight: 600;
            --random-item-translation: 0;

            .s-random_tag.-font:after {
                content: "Semi Bold";
            }
        }

        .s-random_content.-variation-3 & {
            --random-item-font-weight: 400;
            --random-item-translation: calc(#{grid-space(4/8, -1)} - 50%);

            .s-random_tag.-font:after {
                content: "Regular";
            }

        }

        .s-random_content.-variation-4 & {
            --random-item-font-weight: 400;
            --random-item-translation: calc(#{grid-space(4/8, -1)} - 50%);

            .s-random_tag.-font:after {
                content: "Regular";
            }
        }
    }

    &:nth-child(5) {

        .s-random_label {
            left: auto;
            right: 0;
        }

        .s-random_content.-variation-1 & {
            --random-item-font-weight: 900;
            --random-item-translation: 0;

            .s-random_tag.-font:after {
                content: "Black";
            }
        }

        .s-random_content.-variation-2 & {
            --random-item-font-weight: 600;
            --random-item-translation: #{grid-space(-1.5/8)};

            .s-random_tag.-font:after {
                content: "Semi Bold";
            }
        }

        .s-random_content.-variation-3 & {
            --random-item-font-weight: 500;
            --random-item-translation: 0;

            .s-random_tag.-font:after {
                content: "Medium";
            }
        }

        .s-random_content.-variation-4 & {
            --random-item-font-weight: 500;
            --random-item-translation: 0;

            .s-random_tag.-font:after {
                content: "Medium";
            }
        }
    }

    &:nth-child(6) {

        .s-random_content.-variation-1 & {
            --random-item-font-weight: 700;
            --random-item-translation: calc(#{grid-space(8/8, -2)} - 100%);

            .s-random_tag.-font:after {
                content: "Bold";
            }
        }

        .s-random_content.-variation-2 & {
            --random-item-font-weight: 500;
            --random-item-translation: #{grid-space(4/8)};

            .s-random_tag.-font:after {
                content: "Medium";
            }
        }

        .s-random_content.-variation-3 & {
            --random-item-font-weight: 600;
            --random-item-translation: 0;

            .s-random_tag.-font:after {
                content: "Semi Bold";
            }
        }

        .s-random_content.-variation-4 & {
            --random-item-font-weight: 600;
            --random-item-translation: 0;

            .s-random_tag.-font:after {
                content: "Semi Bold";
            }
        }
    }

    &:nth-child(7) {

        .s-random_content.-variation-1 & {
            --random-item-font-weight: 700;
            --random-item-translation: #{grid-space(2/8)};

            .s-random_tag.-font:after {
                content: "Bold";
            }
        }

        .s-random_content.-variation-2 & {
            --random-item-font-weight: 400;
            --random-item-translation: #{grid-space(2.5/8)};

            .s-random_tag.-font:after {
                content: "Regular";
            }
        }

        .s-random_content.-variation-3 & {
            --random-item-font-weight: 600;
            --random-item-translation: calc(#{grid-space(4/8, -1)} - 50%);

            .s-random_tag.-font:after {
                content: "Semi Bold";
            }
        }

        .s-random_content.-variation-4 & {
            --random-item-font-weight: 600;
            --random-item-translation: 0;

            .s-random_tag.-font:after {
                content: "Semi Bold";
            }
        }
    }

    &:nth-child(8) {

        .s-random_content.-variation-1 & {
            --random-item-font-weight: 400;
            --random-item-translation: #{grid-space(1/8)};

            .s-random_tag.-font:after {
                content: "Regular";
            }
        }

        .s-random_content.-variation-2 & {
            --random-item-font-weight: 300;
            --random-item-translation: 0;

            .s-random_tag.-font:after {
                content: "Light";
            }
        }

        .s-random_content.-variation-3 & {
            --random-item-font-weight: 700;
            --random-item-translation: #{grid-space(3/8)};

            .s-random_tag.-font:after {
                content: "Bold";
            }
        }

        .s-random_content.-variation-4 & {
            --random-item-font-weight: 700;
            --random-item-translation: #{grid-space(1/8)};

            .s-random_tag.-font:after {
                content: "Bold";
            }
        }
    }

    @media (max-width: $to-small) {
        width: 100%;

        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(7) {
            text-align: center;
        }

        &:nth-child(5),
        &:nth-child(6) {
            text-align: right;
        }
    }

    @media (min-width: $from-small) {
        transform: translate(var(--random-item-translation, 0), 0);

        &:nth-child(5) {
            margin-top: calc(-1 * var(--random-text-font-size));
            margin-left: auto;
        }
    }
}

.s-random_text {
    display: block;
    letter-spacing: -.05em;
    text-transform: uppercase;

    .o-at_line {
        padding-right: .05em;
    }
}

.s-random_label {
    position: absolute;
    bottom: 100%;
    left: 0;
    display: flex;
    line-height: 1.5;
    font-family: var(--font-family-text);
    font-variation-settings: "wght" 400;
    font-size: rem(12px);
    white-space: nowrap;
    pointer-events: none;

    @media (hover: none) or (max-width: $to-small) {
        display: none;
    }
}

.s-random_tag {
    display: block;
    flex-shrink: 0;
    padding: rem(8px) rem(12px);
    background-color: var(--color-bg);
    border: 1px solid var(--color-text);
    border-radius: 30px;
    opacity: 0;
    transform: translate(0, 50%);

    &.-font {

        &:after {
            display: inline-block;
            margin-left: .2em;
        }
    }
}

</style>
