export const FONT_FAMILIES = Object.freeze({
    SANS: {
        KEY: 'SANS',
        NAME: 'Sans',
        WEIGHT_RANGE: [100, 900],
        CSS_VAR: 'var(--font-family-sans)'
    },
    GLARE: {
        KEY: 'GLARE',
        NAME: 'Glare',
        WEIGHT_RANGE: [100, 900],
        CSS_VAR: 'var(--font-family-glare)'
    },
    SERIF: {
        KEY: 'SERIF',
        NAME: 'Serif',
        WEIGHT_RANGE: [100, 900],
        CSS_VAR: 'var(--font-family-serif)'
    },
    TEXT: {
        KEY: 'TEXT',
        NAME: 'Text',
        WEIGHT_RANGE: [300, 700],
        CSS_VAR: 'var(--font-family-text)'
    }
})

export const FONT_FAMILIES_KEYS = Object.keys(FONT_FAMILIES)

export const FONT_FAMILIES_ARRAY = [
    {
        KEY: 'SANS',
        NAME: 'Sans',
        WEIGHT_RANGE: [100, 900],
        CSS_VAR: 'var(--font-family-sans)'
    },
    {
        KEY: 'GLARE',
        NAME: 'Glare',
        WEIGHT_RANGE: [100, 900],
        CSS_VAR: 'var(--font-family-glare)'
    },
    {
        KEY: 'SERIF',
        NAME: 'Serif',
        WEIGHT_RANGE: [100, 900],
        CSS_VAR: 'var(--font-family-serif)'
    },
    {
        KEY: 'TEXT',
        NAME: 'Text',
        WEIGHT_RANGE: [300, 700],
        CSS_VAR: 'var(--font-family-text)'
    }
]

export const SECTIONS = [
    {
        label: 'Introduction',
        id: 'introduction',
    },
    {
        label: 'From Sans to Serif',
        id: 'from-sans-to-serif',
    },
    {
        label: 'Glyph Set',
        id: 'glyph-set',
    },
    {
        label: 'Font Sampler',
        id: 'font-sampler',
    },
    {
        label: 'Fragment in Use',
        id: 'fragment-in-use',
    },
    {
        label: 'Special Characters',
        id: 'special-characters',
    },
]

export const CSS_CLASS = Object.freeze({
    IS_LOADING: 'is-loading',
    IS_READY: 'is-ready',
    IS_INVIEW: 'is-inview',
    NAV_OPEN: 'has-nav-open',
    MOBILE: 'is-mobile',
    DESKTOP: 'is-desktop',
})

export const CUSTOM_EVENTS = Object.freeze({
    RESIZE_END: 'l.resizeEnd',
    FONTS_LOADED: 'l.fontsLoaded',
    READY: 'l.ready',
    BODY_RESIZE: 'l.bodyResize',
})

export const IS_DEV = process.env.NODE_ENV === 'development'

export const IS_MOBILE =
    /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ||
    /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.platform) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) ||
    'ontouchstart' in document.documentElement && navigator.userAgent.match(/Mobi/) ||
    window.matchMedia('only screen and (max-width: 760px)').matches

export const IS_DESKTOP = !IS_MOBILE

export const PRERENDERING = (window.__PRERENDER_INJECTED && window.__PRERENDER_INJECTED.prerendered)
