/**
 *
 * Data content
 *
**/

export const shopLink = 'https://pangrampangram.com/products/fragment'

export const details = [
    {
        label: 'Styles',
        desc: '4 Cuts x 9 Styles with 581 Glyphs each',
    },
    {
        label: 'Designers',
        desc: 'Francesca Bolognini <br> Mat Desjardins',
    },
    {
        label: 'Release Date',
        desc: 'June 2022',
    },
    {
        label: 'Version',
        desc: '1.00',
    },
    {
        label: 'Available Formats',
        desc: 'OTF, TTF, WOFF, WOFF2, EOT',
    },
]

export const languages = [
    'Afrikaans',
    'Basque',
    'Breton',
    'Catalan',
    'Croatian',
    'Czech',
    'Danish',
    'Dutch',
    'English',
    'Estonian',
    'Finnish',
    'French',
    'Gaelic',
    'German',
    'Hungarian',
    'Icelandic',
    'Indonesian',
    'Irish',
    'Italian',
    'Latvian',
    'Lituanian',
    'Norwegian',
    'Polish',
    'Portuguese',
    'Romanian',
    'Saami',
    'Serbian',
    'Slovak',
    'Slovenian',
    'Spanish',
    'Swahili',
    'Swedish',
    'Turkish',
]

export const posters = {
    path: '/assets/images/posters/',
    bgCount: 8,
    postersCount: 7,
    size: {
        width: 1441,
        height: 2038
    },
}

export const pushes = [
    {
        title: 'Buy pp® Fragment',
        label: 'buy',
        number: '①',
        url: 'https://pangrampangram.com/products/fragment'
    },
    {
        title: 'Try PP® Fragment <br>for free',
        label: 'try for free',
        number: '②',
        url: 'https://pangrampangram.com/products/fragment'
    },
    {
        title: 'Enjoy PP® Fragment <br>Wallpapers',
        label: 'pp® Wallpapers',
        number: '③',
        url: '/assets/downloads/fragment-wallpapers.zip',
        download: true
    }
]

export const glyphs = [
    `!`,
    `"`,
    `#`,
    `$`,
    `%`,
    `;`,
    `'`,
    `(`,
    `)`,
    `*`,
    `+`,
    `,`,
    `-`,
    `.`,
    `/`,
    `0`,
    `1`,
    `2`,
    `3`,
    `4`,
    `5`,
    `6`,
    `7`,
    `8`,
    `9`,
    `:`,
    `;`,
    `;`,
    `=`,
    `;`,
    `?`,
    `@`,
    `A`,
    `B`,
    `C`,
    `D`,
    `E`,
    `F`,
    `G`,
    `H`,
    `I`,
    `J`,
    `K`,
    `L`,
    `M`,
    `N`,
    `O`,
    `P`,
    `Q`,
    `R`,
    `S`,
    `T`,
    `U`,
    `V`,
    `W`,
    `X`,
    `Y`,
    `Z`,
    `[`,
    `\\`,
    `]`,
    `^`,
    `_`,
    `\\`,
    `a`,
    `b`,
    `c`,
    `d`,
    `e`,
    `f`,
    `g`,
    `h`,
    `i`,
    `j`,
    `k`,
    `l`,
    `m`,
    `n`,
    `o`,
    `p`,
    `q`,
    `r`,
    `s`,
    `t`,
    `u`,
    `v`,
    `w`,
    `x`,
    `y`,
    `z`,
    `{`,
    `|`,
    `}`,
    `~`,
    `;`,
    `¡`,
    `¢`,
    `£`,
    `¤`,
    `¥`,
    `¦`,
    `§`,
    `¨`,
    `©`,
    `ª`,
    `«`,
    `¬`,
    `®`,
    `¯`,
    `°`,
    `±`,
    `²`,
    `³`,
    `´`,
    `µ`,
    `¶`,
    `·`,
    `¸`,
    `¹`,
    `º`,
    `»`,
    `¼`,
    `½`,
    `¾`,
    `¿`,
    `À`,
    `Á`,
    `Â`,
    `Ã`,
    `Ä`,
    `Å`,
    `Æ`,
    `Ç`,
    `È`,
    `É`,
    `Ê`,
    `Ë`,
    `Ì`,
    `Í`,
    `Î`,
    `Ï`,
    `Ð`,
    `Ñ`,
    `Ò`,
    `Ó`,
    `Ô`,
    `Õ`,
    `Ö`,
    `×`,
    `Ø`,
    `Ù`,
    `Ú`,
    `Û`,
    `Ü`,
    `Ý`,
    `Þ`,
    `ß`,
    `à`,
    `á`,
    `â`,
    `ã`,
    `ä`,
    `å`,
    `æ`,
    `ç`,
    `è`,
    `é`,
    `ê`,
    `ë`,
    `ì`,
    `í`,
    `î`,
    `ï`,
    `ð`,
    `ñ`,
    `ò`,
    `ó`,
    `ô`,
    `õ`,
    `ö`,
    `÷`,
    `ø`,
    `ù`,
    `ú`,
    `û`,
    `ü`,
    `ý`,
    `þ`,
    `Ā`,
    `ā`,
    `Ă`,
    `ă`,
    `Ą`,
    `ą`,
    `Ć`,
    `ć`,
    `Ĉ`,
    `ĉ`,
    `Ċ`,
    `ċ`,
    `Č`,
    `č`,
    `Ď`,
    `ď`,
    `Đ`,
    `đ`,
    `Ē`,
    `ē`,
    `Ė`,
    `ė`,
    `Ę`,
    `ę`,
    `Ě`,
    `ě`,
    `Ĝ`,
    `ĝ`,
    `Ğ`,
    `ğ`,
    `Ġ`,
    `ġ`,
    `Ģ`,
    `ģ`,
    `Ĥ`,
    `ĥ`,
    `Ħ`,
    `ħ`,
    `Ĩ`,
    `ĩ`,
    `Ī`,
    `ī`,
    `Į`,
    `į`,
    `İ`,
    `ı`,
    `Ĳ`,
    `ĳ`,
    `Ĵ`,
    `ĵ`,
    `Ķ`,
    `ķ`,
    `Ĺ`,
    `ĺ`,
    `Ļ`,
    `ļ`,
    `Ľ`,
    `ľ`,
    `Ŀ`,
    `ŀ`,
    `Ł`,
    `ł`,
    `Ń`,
    `ń`,
    `Ņ`,
    `ņ`,
    `Ň`,
    `ň`,
    `Ŋ`,
    `ŋ`,
    `Ō`,
    `ō`,
    `Ő`,
    `ő`,
    `Œ`,
    `œ`,
    `Ŕ`,
    `ŕ`,
    `Ŗ`,
    `ŗ`,
    `Ř`,
    `ř`,
    `Ś`,
    `ś`,
    `Ŝ`,
    `ŝ`,
    `Ş`,
    `ş`,
    `Š`,
    `š`,
    `Ţ`,
    `ţ`,
    `Ť`,
    `ť`,
    `Ŧ`,
    `ŧ`,
    `Ũ`,
    `ũ`,
    `Ū`,
    `ū`,
    `Ŭ`,
    `ŭ`,
    `Ů`,
    `ů`,
    `Ű`,
    `ű`,
    `Ų`,
    `ų`,
    `Ŵ`,
    `ŵ`,
    `Ŷ`,
    `ŷ`,
    `Ÿ`,
    `Ź`,
    `ź`,
    `Ż`,
    `ż`,
    `Ž`,
    `ž`,
    `Ə`,
    `ƒ`,
    `Ǧ`,
    `ǧ`,
    `Ǽ`,
    `ǽ`,
    `Ǿ`,
    `ǿ`,
    `Ș`,
    `ș`,
    `Ț`,
    `ț`,
    `ȷ`,
    `ə`,
    `ˆ`,
    `ˇ`,
    `˘`,
    `˙`,
    `˚`,
    `˛`,
    `˜`,
    `˝`,
    `>̀`,
    `>́`,
    `>̂`,
    `>̃`,
    `>̄`,
    `>̆`,
    `>̇`,
    `>̈`,
    `>̊`,
    `>̋`,
    `>̌`,
    `>̒`,
    `>̦`,
    `>̧`,
    `>̨`,
    `Δ`,
    `Ω`,
    `μ`,
    `π`,
    `Ẁ`,
    `ẁ`,
    `Ẃ`,
    `ẃ`,
    `Ẅ`,
    `ẅ`,
    `ẞ`,
    `Ẽ`,
    `ẽ`,
    `Ỳ`,
    `ỳ`,
    `Ỹ`,
    `ỹ`,
    `–`,
    `—`,
    `‘`,
    `’`,
    `‚`,
    `“`,
    `”`,
    `„`,
    `†`,
    `‡`,
    `•`,
    `…`,
    `‰`,
    `′`,
    `″`,
    `‹`,
    `›`,
    `‽`,
    `⁄`,
    `⁰`,
    `⁴`,
    `⁵`,
    `⁶`,
    `⁷`,
    `⁸`,
    `⁹`,
    `₀`,
    `₁`,
    `₂`,
    `₃`,
    `₄`,
    `₅`,
    `₆`,
    `₇`,
    `₈`,
    `₉`,
    `€`,
    `₹`,
    `₺`,
    `₿`,
    `ℓ`,
    `№`,
    `℗`,
    `™`,
    `℮`,
    `⅛`,
    `⅜`,
    `⅝`,
    `⅞`,
    `←`,
    `↑`,
    `→`,
    `↓`,
    `↔`,
    `↕`,
    `↖`,
    `↗`,
    `↘`,
    `↙`,
    `∂`,
    `∆`,
    `∏`,
    `∑`,
    `−`,
    `∙`,
    `√`,
    `∞`,
    `∫`,
    `≈`,
    `≠`,
    `≤`,
    `≥`,
    `①`,
    `②`,
    `③`,
    `④`,
    `⑤`,
    `⑥`,
    `⑦`,
    `⑧`,
    `⑨`,
    `⓪`,
    `◊`,
    `○`,
    `●`,
    `✳`,
    `❶`,
    `❷`,
    `❸`,
    `❹`,
    `❺`,
    `❻`,
    `❼`,
    `❽`,
    `❾`,
    `【`,
    `】`,
    `〖`,
    `〗`,
    `ﬁ`,
    `ﬂ `,
]
