<template>
   <div class="s-push | o-container o-sticky-section -desktop">
        <div class="s-push_wrap">
            <overlap
                ref="bg"
                filename="a-cap"
                :total="6"
                class="s-push_overlap"
            />
            <div class="s-push_inner">
                <div
                    class="s-push_item"
                    ref="item"
                    v-for="(push, i) in pushes"
                    :key="`push-${i}`"
                >
                    <a
                        :href="push.url"
                        target="_blank"
                        rel="noopener noreferrer"
                        :download="push.download"
                        class="s-push_button"
                    >
                        <span class="s-push_number">{{ push.number }}</span>
                        <span class="s-push_label">{{ push.label }}</span>
                        <anim-text
                            tag="span"
                            :text="push.title"
                            :visible="true"
                            type="chars"
                            class="s-push_title"
                        />
                    </a>
                </div>
            </div>
        </div>
   </div>
</template>

<script>

import AnimText                     from '@/templates/objects/AnimText'
import Overlap                      from '@/templates/components/Overlap'

import { CUSTOM_EVENTS, IS_MOBILE } from '@/constants'
import { vh }                       from '@/utils/vh'
import { gsap }                     from '@/gsap'
import { pushes }                   from '@/data'

export default {
    name: 'Push',
    components: {
        AnimText,
        Overlap
    },
    data: () => ({
        IS_MOBILE,
        pushes,
    }),
    mounted() {

        if(IS_MOBILE) {
            return
        }

        window.addEventListener(CUSTOM_EVENTS.READY, () => {
            this.setTimeline()
            window.addEventListener(CUSTOM_EVENTS.RESIZE_END, this.onResize = () => this.setTimeline())
        })
    },
    methods: {
        setTimeline() {

            if(this.tl) {
                this.tl.kill()
                gsap.set([this.$refs.bg.$el, ...this.$overlaps, ...this.$refs.item], {
                    clearProps: 'all'
                })
            }

            this.tl = gsap.timeline({
                scrollTrigger: {
                    trigger: this.$el,
                    start: 'top top',
                    end: 'bottom bottom',
                    scrub: true
                },
                defaults: {
                    duration: 1.5,
                    ease: 'power2.out'
                },
            })

            this.$overlaps = this.$refs.bg.overlap

            this.tl
                .from(this.$refs.bg.$el, {
                    opacity: 0,
                }, 'in')
                .from(this.$overlaps[0], {
                    yPercent: -25,
                }, 'in')
                .from(this.$overlaps[1], {
                    xPercent: -25,
                    yPercent: -100,
                    rotation: -45,
                }, 'in')
                .from(this.$overlaps[2], {
                    xPercent: 50,
                    yPercent: -50,
                    rotation: 45,
                }, 'in')
                .from(this.$overlaps[3], {
                    xPercent: -20,
                    yPercent: 25,
                    rotation: -30,
                }, 'in')
                .from(this.$overlaps[4], {
                    xPercent: 30,
                    yPercent: -40,
                    rotation: -25,
                }, 'in')
                .from(this.$overlaps[5], {
                    xPercent: -30,
                    yPercent: -5,
                    rotation: -20,
                }, 'in')
                .from(this.$refs.item,
                    {
                        y: vh(100),
                        duration: 1,
                        stagger: .2,
                        // ease: 'power2.in'
                    },
                '-=1')
                .to(this.$refs.item,
                    {
                        y: vh(-100),
                        duration: 1,
                        stagger: .2,
                        ease: 'power2.in'
                    },
                )
                .addLabel('out', '-=.5')
                .to(this.$refs.bg.$el, {
                    opacity: 0,
                    ease: 'power2.in'
                }, 'out')
                .to(this.$overlaps[0], {
                    yPercent: -25,
                    ease: 'power2.in'
                }, 'out')
                .to(this.$overlaps[1], {
                    xPercent: -25,
                    yPercent: -100,
                    rotation: 45,
                    ease: 'power2.in'
                }, 'out')
                .to(this.$overlaps[2], {
                    xPercent: 50,
                    yPercent: 20,
                    rotation: 25,
                    ease: 'power2.in'
                }, 'out')
                .to(this.$overlaps[3], {
                    xPercent: -20,
                    yPercent: 25,
                    rotation: 30,
                    ease: 'power2.in'
                }, 'out')
                .to(this.$overlaps[4], {
                    xPercent: 30,
                    yPercent: -40,
                    rotation: 25,
                    ease: 'power2.in'
                }, 'out')
                .to(this.$overlaps[5], {
                    xPercent: -30,
                    yPercent: -5,
                    rotation: 20,
                    ease: 'power2.in'
                }, 'out')
        }
    },
}

</script>

<style lang="scss">

.s-push {
    --sticky-section-height: #{vh(400)};
    --push-number-size: #{responsive-type(148px, 240px, 1800)};

    padding-top: var(--grid-gutter);
    padding-bottom: var(--grid-gutter);

    @media (min-width: $from-small) {

        html.is-desktop & {
            margin-top: vh(-25);
            margin-bottom: vh(-25);
        }
    }
}

.s-push_wrap {
    display: flex;
    align-items: center;
}

.s-push_overlap {
    --overlap-height: var(--height-gutters);

    html.is-mobile & {
        position: absolute;
        top: vh(-75);
    }
}

.s-push_inner {
    display: grid;
    grid-gap: var(--grid-gutter);

    @media (min-width: $from-small) {
        grid-auto-rows: 1fr;
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media (max-width: $to-medium) {
        width: 100%;
    }

    @media (min-width: $from-medium) {
        width: grid-space(6/8);
        margin-right: auto;
        margin-left: auto;
    }
}

.s-push_item {
    // will-change: transform;

    @media (min-width: $from-small) {

        &:nth-child(1) {
            grid-row: 1/3;
        }

        &:nth-child(2) {
            grid-row: 2/4;
        }

        &:nth-child(3) {
            grid-column-start: 3;
            grid-row: 3/5;
        }
    }
}

.s-push_button {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: rem(16px);
    font-size: rem(14px);
    font-variation-settings: "wght" 500;
    color: var(--color-bg);
    text-align: center;
    text-transform: uppercase;
    background-color: var(--color-text);

    &:after {
        content: "\2192";
        margin-top: auto;
        transition: transform .8s $ease-power3-in-out;
    }

    &:hover {

        &:after {
            transform: translate(100%, 0);
        }

        .s-push_title {
            @include at-roll;
        }
    }

    @media (max-width: $to-small) {
        height: calc(#{vh(100/3)} - 1.33 * var(--grid-gutter));
    }

    @media (min-width: $from-small) {
        min-height: 30vmin;

        // &:nth-child(1) {
        //     grid-row: 1/3;
        // }

        // &:nth-child(2) {
        //     grid-row: 2/4;
        // }

        // &:nth-child(3) {
        //     grid-column-start: 3;
        //     grid-row: 3/5;
        // }
    }
}

.s-push_number {
    top: -.1em;
}

.s-push_label {
    margin-left: auto;

    @media (max-width: $to-small) {
    }

    @media (min-width: $from-small) {
    }
}

.s-push_title {
    --at-easing-in  : #{$ease-sine-in-out};

    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 1.1;
    font-family: var(--font-family-serif);
    font-size: rem(34px);
    font-variation-settings: "wght" 300;
}

</style>
