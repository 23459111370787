<template>
   <div class="s-info | o-sticky-section">
        <div class="s-info_wrap">
            <overlap
                ref="bg"
                filename="a"
                :total="3"
                class="s-info_overlap"
            />
            <div class="s-info_inner | o-container">
                <p class="s-info_item">
                    <rolling-text
                        :visible="textVisible"
                        v-bind="rollingText1"
                        class="s-info_item"
                    />
                </p>
                <p class="s-info_item">
                    <rolling-text
                        :visible="textVisible"
                        v-bind="rollingText2"
                        class="s-info_item"
                    />
                </p>
                <p class="s-info_item">
                    <rolling-text
                        :visible="textVisible"
                        v-bind="rollingText3"
                        class="s-info_item"
                    />
                </p>
           </div>
       </div>
   </div>
</template>

<script>

import RollingText              from '@/templates/objects/RollingText'
import Overlap                  from '@/templates/components/Overlap'
import { gsap }                 from '@/gsap'
import { FONT_FAMILIES }        from '@/constants'

export default {
    name: 'Info',
    components: {
        RollingText,
        Overlap
    },
    data: () => ({
        textVisible: false,
        rollingText1: {
            text: '4 Cuts',
            fonts: [ FONT_FAMILIES.SANS.KEY ],
            defaultFont: FONT_FAMILIES.SERIF.KEY
        },
        rollingText2: {
            text: '9 Styles',
            fonts: [ FONT_FAMILIES.SANS.KEY ],
            defaultFont: FONT_FAMILIES.GLARE.KEY
        },
        rollingText3: {
            text: '581 Glyphs',
            fonts: [ FONT_FAMILIES.SERIF.KEY ],
            defaultFont: FONT_FAMILIES.SANS.KEY
        },
    }),
    mounted() {
        this.tl = gsap.timeline({
            scrollTrigger: {
                trigger: this.$el,
                start: 'top top',
                end: 'bottom bottom',
                scrub: true
            },
            defaults: {
                duration: 1.5,
                ease: 'power2.out'
            },
        })

        this.$overlaps = this.$refs.bg.overlap

        gsap.set(this.$refs.bg.$el, {
            opacity: 0
        })

        this.tl
            .set(this.$refs.bg.$el, {
                opacity: 1
            })
            .from(this.$overlaps[0], {
                xPercent: 50,
                yPercent: -25,
                rotation: 20,
                opacity: 0,
                ease: 'power2.out'
            }, 'in')
            .from(this.$overlaps[1], {
                xPercent: -25,
                yPercent: -100,
                rotation: -30,
                opacity: 0,
                ease: 'power2.out'
            }, 'in')
            .from(this.$overlaps[2], {
                xPercent: -50,
                yPercent: 15,
                rotation: -45,
                opacity: 0,
                ease: 'power2.out'
            }, 'in')
            .add(() => {
                this.textVisible = true
            }, '<20%')
            // .set({}, {}, '+=.5')
            .to(this.$overlaps[0], {
                xPercent: 15,
                yPercent: -100,
                opacity: .5,
                ease: 'power3.in'
            }, 'out')
            .to(this.$overlaps[1], {
                xPercent: -15,
                yPercent: 60,
                opacity: .5,
                ease: 'power3.in'
            }, 'out')
            .to(this.$overlaps[2], {
                xPercent: -15,
                yPercent: 60,
                opacity: .5,
                ease: 'power3.in'
            }, 'out')
            .add(() => {
                this.textVisible = true
            })
            .set({}, {}, '+=.5')
    },
}

</script>

<style lang="scss">

.s-info {
    --sticky-section-height: #{vh(400)};

   margin-top: vh(10);
   margin-bottom: vh(-100);
   font-size: 12vw;
   font-variation-settings: "wght" 200;
   text-transform: uppercase;
}

.s-info_overlap {
    --overlap-height: var(--height-gutters);

    // transition: opacity $speed $easing, transform $speed $easing;

    // &:not(.is-visible) {
    //     opacity: 0;
    //     transform: translate(0, 10%);
    // }
}

.s-info_inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.s-info_item {

    &:nth-child(1) {

    }

    &:nth-child(2) {
        --rolling-text-delay: .2s;
        text-align: right;
    }

    &:nth-child(3) {
        --rolling-text-delay: .4s;
        text-align: center;
    }
}



</style>
