<template>
    <div class="o-rail">
        <div
            ref="inner"
            class="o-rail_inner"
        >
            <div ref="content" class="o-rail_content">
                <span class="o-text -text">unlimited combinations</span>
                <span class="o-text -sans">and so many possibilities</span>
            </div>
        </div>
    </div>
</template>

<script>

import { gsap }                 from '@/gsap'
import { CUSTOM_EVENTS }        from '@/constants'

export default {
    name: 'Rail',
    mounted() {
        this.$inner = this.$refs.inner
        this.$content = this.$refs.content

        window.addEventListener(CUSTOM_EVENTS.READY, () => {
            this.set()
            window.addEventListener(CUSTOM_EVENTS.RESIZE_END, this.onResize = () => this.set())
        })
    },
    methods: {
        set() {
            const railWidth = this.$el.offsetWidth
            const railContentWidth = this.$content.offsetWidth
            let railInnerWidth = railContentWidth

            // Remove clones
            while (this.$inner.children.length > 1) {
                this.$inner.removeChild(this.$inner.lastChild)
            }

            // Create clones
            let $clone
            while (railInnerWidth < 3 * railWidth) {
                $clone = this.$content.cloneNode(true)
                this.$inner.appendChild($clone)
                railInnerWidth += railContentWidth
            }

            // Compute translateX size
            let translateX = 0
            while (translateX < railWidth) {
                translateX += railContentWidth
            }

            this.tl = gsap.fromTo(this.$refs.inner,
                {
                    x: 0
                },
                {
                    x: -translateX,
                    duration: translateX/150, // Duration proportional to translate
                    ease: 'none',
                    repeat: -1
                })
        },
        toggle(play=true) {

            if(!this.tl) {
                return
            }

            if(play) {
                this.tl.play()
            } else {
                this.tl.pause()
            }
        }
    }
}

</script>

<style lang="scss">

.o-rail {
    // --rail-speed: 10s;
    // --rail-translate-x: v-bind(x);

    width: 100%;
    overflow: hidden;
}

.o-rail_inner {
    display: flex;
    white-space: nowrap;
    // animation: anim-rail var(--rail-speed) linear infinite;
    will-change: transform;
}

.o-rail_content {
    font-size: rem(24px);
    text-transform: uppercase;

    span {

        &:before {
            display: inline-block;
            width: 4em;
            text-align: center;
        }

        &:nth-child(odd) {

            &:before {
                content: "\2733";
            }
        }

        &:nth-child(even) {

            &:before {
                content: "\25CF";
            }
        }
    }
}

// // Animation
// @keyframes anim-rail {
//     to {
//         transform: translate(var(--rail-translate-x), 0)
//     }
// }

</style>
