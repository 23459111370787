import { CUSTOM_EVENTS, IS_DEV } from '@/constants'
import { createApp } from 'vue'
import debounce from '@/utils/debounce'
import App from './App.vue'
import inview from '@/directives/inview'
import '@/assets/styles/main.scss'

// Add resizeEnd event to window
const resizeEndEvent = new CustomEvent(CUSTOM_EVENTS.RESIZE_END);
window.addEventListener('resize', debounce(() => {
    window.dispatchEvent(resizeEndEvent)
}, 200, false))

// Watch body height change
let timeout
const bodyResizeEvent = new CustomEvent(CUSTOM_EVENTS.BODY_RESIZE);
new ResizeObserver(() => {

    if(timeout) {
        clearTimeout(timeout)
    }

    timeout = setTimeout(() => {
        window.dispatchEvent(bodyResizeEvent)
    }, 200)
}).observe(document.body)

// Create app
const app = createApp(App)

// Directives
app.directive('inview', inview)

// Mount
app.mount('#app')

// Credits log
if(!IS_DEV) {
    console.log(' ');
    console.log('%c Made by Locomotive 🚂','background: #1c1c20; color: #fff; font-weight: 500; padding: 5px 10px 5px 5px;');
    console.log('%c https://locomotive.ca', 'background: #1c1c20; color: #fff; font-weight: 500; padding: 5px 10px 5px 5px;');
    console.log(' ');
    console.log('🔧 Vue   → https://vuejs.org');
    console.log('🔧 GSAP  → https://greensock.com');
    console.log('🔧 Lenis → https://lenis.studiofreight.com');
    console.log(' ');
}
