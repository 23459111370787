<template>
    <span
        class="o-cursor"
        :class="className"
    >
        <span class="o-cursor_inner">
            ↖
        </span>
    </span>
</template>

<script>

import { gsap } from '@/gsap';

export default {
    name: 'AppCursor',
    data: () => ({
        isActive: false,
        isVisible: false,
    }),
    mounted() {

        gsap.set(this.$el, {
            x: window.innerWidth/2,
            y: window.innerHeight/2,
            ease: 'power3.out',
        })

        // Document events
        document.addEventListener('mousemove', e => {

            if(!this.isVisible) {
                this.isVisible = true
            }

            this.move(e.clientX, e.clientY)
        })

        // Window events
        window.addEventListener('mousedown', () => {
            this.isActive = true
        })

        window.addEventListener('mouseup', () => {
            this.isActive = false
        })
    },
    computed: {
        // ...mapGetters({
        //     state: 'cursor/currentState',
        // }),
        className() {
            let className = ''

            // if (this.state) {
            //     className += ` -${this.state}`
            // }
            if (this.isActive) {
                className += ' is-active'
            }

            if (!this.isVisible) {
                className += ' -hide'
            }

            return className
        },
    },
    methods: {
        move(x, y) {
            gsap.set(this.$el, {
                x,
                y,
            })
        },
    },
}

</script>

<style lang="scss">

* {
    cursor: none !important;
}

.o-cursor {
    --cursor-color: currentColor;
    --cursor-size: #{rem(40px)};

    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    width: var(--cursor-size);
    height: var(--cursor-size);
    margin-top: calc(-0.5 * var(--cursor-size));
    margin-left: calc(-0.5 * var(--cursor-size));
    pointer-events: none;
    transition: color $speed $easing;
    mix-blend-mode: difference;
    will-change: transform;

    @media (hover: none) {
        display: none;
        visibility: hidden;
    }
}

.o-cursor_inner {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: bottom;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    padding-bottom: .2em;
    line-height: 1;
    font-size: rem(46px);
    transition: transform $speed $easing;
    transform-origin: 100% 100%;
    will-change: transform;

    .o-cursor.-click & {
        transform: scale(.8);
    }

    .o-cursor.-hide & {
        transform: scale(0);
    }
}


/*----------  Animations  ----------*/

@keyframes anim-cursor-loader-rotate {
    100% {
        transform: rotate(1turn);
    }
}

@keyframes anim-cursor-loader-dash {
    0% {
        stroke-dasharray: 1,200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89,200;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 89,200;
        stroke-dashoffset: -124;
    }
}



</style>
