<template>
    <main id="top">
        <hero />
        <intro :id="sections[0].id" />
        <info />
        <random :id="sections[1].id" />
        <glyph-viewer :id="sections[2].id" />
        <playground :id="sections[3].id" />
        <posters :id="sections[4].id" />
        <div class="c-home_details | o-container">
            <overlap
                ref="bg"
                filename="smgkuq"
                :total="6"
                class="c-home_overlap"
            />
            <languages />
            <variable :id="sections[5].id" />
        </div>
        <push />
        <credits />
    </main>
</template>

<script>

import Overlap                                  from '@/templates/components/Overlap'
import Hero                                     from '@/templates/sections/Hero'
import Intro                                    from '@/templates/sections/Intro'
import Info                                     from '@/templates/sections/Info'
import Random                                   from '@/templates/sections/Random'
import GlyphViewer                              from '@/templates/sections/GlyphViewer'
import Playground                               from '@/templates/sections/Playground'
import Posters                                  from '@/templates/sections/Posters'
import Languages                                from '@/templates/sections/Languages'
import Variable                                 from '@/templates/sections/Variable'
import Push                                     from '@/templates/sections/Push'
import Credits                                     from '@/templates/sections/Credits'

import { CUSTOM_EVENTS, IS_MOBILE, SECTIONS }   from '@/constants'
import { gsap }                                 from '@/gsap'

export default {
    name: 'Home',
    components: {
        Overlap,
        Hero,
        Intro,
        Info,
        Random,
        GlyphViewer,
        Playground,
        Posters,
        Languages,
        Variable,
        Push,
        Credits,
    },
    data: () => ({
        sections: SECTIONS
    }),
    mounted() {

        if(IS_MOBILE) {
            return
        }

        this.$overlaps = this.$refs.bg.overlap

        window.addEventListener(CUSTOM_EVENTS.READY, () => {
            this.setTimeline()
            window.addEventListener(CUSTOM_EVENTS.RESIZE_END, this.onResize = () => this.setTimeline())
        })
    },
    methods: {
        setTimeline() {

            if(this.tl) {
                this.tl.kill()
                gsap.set(this.$overlaps, {
                    clearProps: 'all'
                })
            }

            this.tl = gsap.timeline({
                scrollTrigger: {
                    trigger: this.$refs.bg.$el,
                    start: 'top bottom',
                    end: 'bottom top',
                    scrub: true,
                    defaults: {
                        duration: 2,
                        ease: 'power2.out'
                    },
                }
            })

            this.tl
                .from(this.$overlaps[0], {
                    xPercent: -20,
                    yPercent: 20,
                    opacity: 0,
                }, 0)
                .from(this.$overlaps[1], {
                    xPercent: 20,
                    // yPercent: 20,
                    opacity: 0,
                }, 0)
                .from(this.$overlaps[2], {
                    yPercent: 15,
                    opacity: 0,
                    scale: 1.2,
                    ease: 'power2.inOut'
                }, 0)
                .from(this.$overlaps[3], {
                    xPercent: -20,
                    yPercent: 15,
                    opacity: 0,
                    ease: 'power2.inOut'
                }, 0)
                .from(this.$overlaps[4], {
                    yPercent: 50,
                    opacity: 0,
                    ease: 'power2.inOut'
                }, 0)
                .from(this.$overlaps[5], {
                    xPercent: 20,
                    opacity: 0,
                    ease: 'power2.inOut'
                }, 0)
        }
    },
}

</script>

<style lang="scss">

.c-home_details {

    @media (min-width: $from-small) {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        min-height: calc(2303/2000 * 100vw); // Overlap height
    }
}

.c-home_overlap {
    --overlap-width: 100vw;

    overflow-x: hidden;
    overflow-y: visible;
    opacity: .7;

    .c-overlap_inner {
        top: 0;
    }

    @media (max-width: $to-small) {
        --overlap-height: calc(2303/2000 * 100vw);

        top: vh(10);
    }
}

</style>
