import { CSS_CLASS, CUSTOM_EVENTS } from '@/constants'
import { ScrollTrigger } from '@/gsap'

const defaultOptions = {
    start: 'top bottom',
    end: 'bottom top'
}

const Inview = {
    created($el, binding) {
        $el.enabled = binding.value !== false

        if($el.enabled) {
            let options = typeof binding.value === 'object' ? binding.value : {}
            options = Object.assign({...defaultOptions}, options)

            window.addEventListener(CUSTOM_EVENTS.READY, () => {
                $el.st = ScrollTrigger.create({
                    trigger: $el,
                    ...options,
                    onEnter: () => {
                        $el.classList.add(CSS_CLASS.IS_INVIEW)
                    },
                    onEnterBack: () => {
                        $el.classList.add(CSS_CLASS.IS_INVIEW)
                    }
                })
            })
        }
    },
    beforeUnmounted($el) {

        if($el.enabled) {
            $el.st.kill()
        }
    }
}

export default Inview
