<template>
    <component
        :is="tag"
        :href="href"
        class="c-button"
        :class="[
            { '-border': border },
            { '-stretch': stretch },
        ]"
    >
        <anim-text
            tag="span"
            :text="label"
            :visible="true"
            type="chars"
            class="c-button_label | o-text-caps"
        />
    </component>
</template>

<script>

import AnimText                     from '@/templates/objects/AnimText'

export default {
    name: 'AppButton',
    components: {
        AnimText
    },
    props: {
        tag: {
            type: String,
            default: 'a'
        },
        href: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        border: {
            type: Boolean,
            default: false
        },
        stretch: {
            type: Boolean,
            default: false
        }
    }
}

</script>

<style lang="scss">

.c-button {
    display: inline-flex;
    padding: rem(12px) rem(20px);
    color: var(--color-bg);
    background-color: var(--color-text);
    transition: opacity $speed $easing;

    &.-border {
        color: var(--color-text);
        background-color: transparent;
        border: 1px solid currentColor
    }

    &.-stretch {
        width: 100%;
        justify-content: center;
        text-align: center;
    }

    &.is-disabled {
        opacity: .5;
        pointer-events: none;
    }

    &:hover {

        .c-button_label {
            @include at-roll;
        }
    }
}

.c-button_label {
    --at-duration-in: .8s;
}


</style>
