<template>
    <div class="c-overlap">
        <div class="c-overlap_inner">
            <div
                v-for="counter in total"
                :key="`overlap-${filename}-${counter}`"
                class="c-overlap_item"
                ref="overlap"
            >
                <asset
                    :url="`/assets/images/layout/overlaps/${filename}-${counter}.png`"
                    :contain="true"
                    :preload="preload"
                    class="c-overlap_asset"
                />
            </div>
        </div>
    </div>
</template>

<script>

import Asset                from '@/templates/objects/Asset'

export default {
    name: 'Overlap',
    components: {
        Asset
    },
    props: {
        filename: {
            type: String,
            required: true
        },
        total: {
            type: Number,
            default: 1
        },
        preload: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        overlap() {
            return this.$refs.overlap
        }
    }
}

</script>

<style lang="scss">

.c-overlap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    overflow: hidden;
}

.c-overlap_inner {
    position: absolute;
    top: calc(50% - 0.5 * var(--overlap-height, 100%));
    left: calc(50% - 0.5 * var(--overlap-width, 100%));
    width: var(--overlap-width, 100%);
    height: var(--overlap-height, 100%);
}

.c-overlap_item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    will-change: transform;
}

.c-overlap_asset {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

</style>
