<template>
   <div class="s-variable">
        <anim-text
            class="s-variable_title"
            tag="p"
            :reveal="true"
            text="What sticks <br> to memory, <br> often, are <br> those odd <br> 【little <br> fragments】<br>
            that have no <br> beginning <br> and no end. <br> †"
            v-inview="{ onUpdate }"
        />
   </div>
</template>

<script>

import AnimText         from '@/templates/objects/AnimText'

export default {
    name: 'Variable',
    components: {
        AnimText
    },
    data: () => ({
        variationWeight: 100
    }),
    methods: {
        onUpdate({progress}) {
            this.variationWeight = Math.round(100 + progress * 800)
        }
    },
}

</script>

<style lang="scss">

.s-variable {
    z-index: z("above");
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: vh(20);
    padding-bottom: vh(20);
    min-height: var(--height-gutters);
    font-size: var(--font-size-h1);
    text-align: center;
}

.s-variable_title {
    font-size: inherit;
    font-family: var(--font-family-serif);
    font-variant-ligatures: discretionary-ligatures;
    font-variation-settings: "wght" v-bind(variationWeight);
    text-transform: uppercase;
}

</style>
