<template>
    <div ref="container">
        <header class="c-header">
            <div class="c-header_inner">
                <h1 class="c-header_heading | o-text-caps | u-hide@to-small">
                    <a
                        href="https://pangrampangram.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="o-link"
                    >
                        Pangram Pangram&reg; Foundry
                    </a>
                    +
                    <a
                        href="https://locomotive.ca"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="o-link"
                    >
                        Locomotive&reg;
                    </a>
                </h1>
                <a
                    href="#top"
                    class="c-header_title | o-text-caps o-link"
                >
                    PP® Fragment
                </a>
                <app-button
                    :href="shopLink"
                    target="_blank"
                    rel="noopener noreferrer"
                    label="Get the font"
                    class="c-header_button | u-hide@to-small"
                />
            </div>
        </header>
        <div class="c-menu">
            <div class="c-menu_inner">
                <div class="c-menu_top">
                    <anim-text
                        tag="h2"
                        text="Fragment"
                        :visible="isOpen"
                        type="chars"
                        class="c-menu_title"
                    />
                    <nav class="c-menu_nav">
                        <ul class="c-menu_list">
                            <li
                                v-for="section in sections"
                                :key="`menu-item-${section.id}`"
                                class="c-menu_item"
                            >
                                <a
                                    :href="`/#${section.id}`"
                                    class="c-menu_link | o-link"
                                    @click="close"
                                >
                                    <anim-text
                                        tag="span"
                                        :text="section.label"
                                        :visible="isOpen"
                                    />
                                </a>
                            </li>
                        </ul>
                    </nav>
                    <a
                        :href="shopLink"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="c-menu_shop | o-link"
                    >
                        <anim-text
                            tag="span"
                            text="Get 〖the font〗"
                            :visible="isOpen"
                            type="chars"
                        />
                    </a>
                </div>
                <a
                    href="https://pangrampangram.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="c-menu_link | o-link"
                >
                    Pangram Pangram® Foundry
                </a>
                <a
                    href="https://locomotive.ca/en"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="c-menu_link | o-link"
                >
                    Locomotive
                </a>
                <ul class="c-menu_bottom">
                    <li
                        v-for="(item, i) in subnav"
                        :key="`menu-subnav-item-${i}`"
                    >
                        <a
                            :href="item.url"
                            target="_blank"
                            rel="noopener noreferrer"
                            class="o-link"
                        >
                            {{ item.label }}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="c-sidebar">
            <button
                class="c-sidebar_proxy | u-hide@to-small"
                @click="close"
                tabindex="-1"
            >
                <span class="u-screen-reader-text">Close menu</span>
            </button>
            <button
                class="c-sidebar_inner"
                @click="toggle"
                tabindex="-1"
            >
                <span class="c-sidebar_label">Table of Contents</span>
                <span class="c-sidebar_toggler"></span>
            </button>
        </div>
    </div>
</template>

<script>

import AnimText                 from '@/templates/objects/AnimText'
import AppButton                from '@/templates/components/AppButton'

import { shopLink }             from '@/data'
import { CSS_CLASS, SECTIONS }  from '@/constants'
import { createFocusTrap }      from 'focus-trap'

const $html = document.documentElement

export default {
    name: 'AppHeader',
    components: {
        AnimText,
        AppButton,
    },
    data: () => ({
        shopLink,
        sections: SECTIONS,
        isOpen: false,
        subnav: [
            {
                label: 'Terms & Conditions',
                url: 'https://pangrampangram.com/pages/faq#font-licensing'
            },
            {
                label: 'FAQ',
                url: 'https://pangrampangram.com/pages/faq'
            },
            {
                label: 'Contact',
                url: 'https://pangrampangram.com/pages/contact-us'
            },
        ]
    }),
    mounted() {
        this.focusTrap = createFocusTrap(this.$refs.container, {
            /**
             * There is a delay between when the class is applied
             * and when the element is focusable
             */
            checkCanFocusTrap: (trapContainers) => {
                console.log(trapContainers)
                const results = trapContainers.map((trapContainer) => {
                    return new Promise((resolve) => {
                        const interval = setInterval(() => {
                            if (getComputedStyle(trapContainer).visibility !== 'hidden') {
                                resolve();
                                clearInterval(interval)
                            }
                        }, 5)
                    });
                });

                // Return a promise that resolves when all the trap containers are able to receive focus
                return Promise.all(results)
            }
        })
    },
    methods: {
        open() {
            this.isOpen = true
            $html.classList.add(CSS_CLASS.NAV_OPEN)
            this.focusTrap?.activate?.()

            document.addEventListener('keyup', this.closeBind)
        },
        close() {
            this.isOpen = false
            $html.classList.remove(CSS_CLASS.NAV_OPEN)
            this.focusTrap?.deactivate?.()

            document.removeEventListener('keyup', this.closeBind)
        },
        toggle() {
            if(this.isOpen) {
                this.close()
            } else {
                this.open()
            }
        },
        closeBind(e) {
            if (e.key === "Escape") {
                this.close()
            }
        }
    }
}

</script>

<style lang="scss">

.c-header {
    z-index: z("header");
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: var(--header-height);
    padding-right: var(--container-padding-x);
    padding-left: var(--container-padding-x);

    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 1px;
        background-color: currentColor;
        opacity: .2;
    }

    html:not(.is-ready) & {
        opacity: 0;
        transform: translate(0, -100%);
    }

    html.is-ready & {
        transition: opacity $speed-slow $easing 2.5s, transform $speed-slow $easing 2.5s;
    }

    // @media (max-width: $to-small) {
    //     background-color: $color-darkest;
    // }

    // @media (min-width: $from-small) {
    //     mix-blend-mode: difference;
    // }
    background-color: $color-darkest;
}

.c-header_inner {
    display: flex;
    width: 100%;

    @media (max-width: $to-small) {
        align-items: baseline;
    }

    @media (min-width: $from-small) {
        align-items: center;
    }
}

.c-header_heading {
}

.c-header_title {

    @media (max-width: $to-small) {
        // width: grid-space(4/8, 1);
    }

    @media (min-width: $from-small) {
        position: absolute;
        top: 50%;
        left: calc(50% + 0.5 * var(--sidebar-width));
        text-align: center;
        transform: translate(-50%, -50%);
    }
}

.c-header_button {
    margin-left: auto;
}


.c-menu,
.c-sidebar {
    top: var(--header-height);
    bottom: 0;
    height: auto;
    transition: transform .6s $ease-power2-out;
}

/*----------  Sidebar   ----------*/

.c-sidebar {
    z-index: z("header");

    @media (min-width: $from-small) {
        position: fixed;
        left: 0;
        width: var(--sidebar-width);

        html.has-nav-open & {
            transform: translate(var(--menu-width), 0);
        }
    }
}

.c-sidebar_proxy {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;

    html:not(.has-nav-open) & {
        left: 200vw;
    }
}

.c-sidebar_inner {

    @media (max-width: $to-small) {
        position: fixed;
        top: 0;
        right: 0;
        width: var(--header-height);
        height: var(--header-height);
        opacity: 0;
        transform: translate(0, -100%);

        html.is-ready & {
            opacity: 1;
            transform: translate(0);
            transition: opacity $speed-slow $easing 2.5s, transform $speed-slow $easing 2.5s;
        }
    }

    @media (min-width: $from-small) {
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - 2px);
        height: 100%;
        opacity: 0;
        transform: translate(-100%, 0);

        &:after,
        &:before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            display: block;
            height: 100%;
        }

        &:before {
            width: calc(100% + 1px); // Avoir glitch on translate
            background-color: $color-darkest;
        }

        &:after {
            width: 1px;
            height: 100%;
            background-color: currentColor;
            opacity: .2;
        }

        html.is-ready & {
            opacity: 1;
            transform: translate(0);
            transition: opacity $speed-slow $easing 2.8s, transform $speed-slow $easing 2.8s;
        }
    }
}

.c-sidebar_label {
    position: absolute;
    bottom: var(--grid-gutter);
    right: 0;
    display: flex;
    align-items: center;
    width: var(--sidebar-width);
    font-size: inherit;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    white-space: nowrap;
    transform: rotate(180deg);

    @media (max-width: $to-small) {
        @include u-accessibly-hidden;
    }
}

.c-sidebar_toggler {
    right: 0;
    display: block;

    &:after,
    &:before {
        content: "";
        position: absolute;
        left: 25%;
        display: block;
        width: 50%;
        height: 2px;
        background-color: currentColor;
        transition: transform .6s $easing;
    }

    &:before {
        bottom: calc(50% - 5px);
    }

    &:after {
        top: calc(50% - 5px);
    }

    html:not(.has-nav-open) &:hover {

        &:before {
            transform: translate(0, 75%);
        }

        &:after {
            transform: translate(0, -75%);
        }
    }

    html.has-nav-open & {

        &:before {
            transform: rotate(45deg) translate(-9%, -140%);
        }

        &:after {
            transform: rotate(-45deg) translate(-9%, 140%);
        }
    }

    html.has-nav-open &:hover {

        &:before {
            transform: rotate(45deg) translate(-9%, -140%) scale(.75, 1);
        }

        &:after {
            transform: rotate(-45deg) translate(-9%, 140%) scale(.75, 1);
        }
    }

    @media (max-width: $to-small) {
        width: 100%;
        height: 100%;
    }

    @media (min-width: $from-small) {
        position: absolute;
        top: calc(50% - 0.5 * var(--sidebar-width));
        width: var(--sidebar-width);
        height: var(--sidebar-width);
    }
}

/*----------  Menu   ----------*/

.c-menu {
    z-index: z("header");
    position: fixed;
    left: 0;
    width: var(--menu-width);
    padding: var(--grid-gutter);
    font-variation-settings: "wght" 300;
    background-color: $color-darkest;
    transform: translate(calc(-1 * var(--menu-width)));

    .o-at {
        --at-duration-in: .6s;
        --at-duration-out: .01s;
        --at-delay-out: .6s;
    }

    html.has-nav-open & {
        transform: translate(0);
    }
}

.c-menu_inner {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.c-menu_top {
    margin-bottom: auto;

    @media (max-width: $to-small) {
        font-size: rem(32px);
    }

    @media (min-width: $from-small) {
        font-size: rem(28px);
    }
}

.c-menu_title {
    padding-top: .75em;
    padding-bottom: 2em;
    font-family: var(--font-family-serif);
    font-size: inherit;
}

.c-menu_nav {
    margin-bottom: 1.5em;
}

.c-menu_list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.c-menu_item {

    &:nth-child(1) .c-menu_link {

        &:before {
            content: "\2460";
        }

        .o-at {
            --at-delay-in: .2s;
        }
    }

    &:nth-child(2) .c-menu_link {

        &:before {
            content: "\2461";
        }

        .o-at {
            --at-delay-in: .25s;
        }
    }

    &:nth-child(3) .c-menu_link {

        &:before {
            content: "\2462";
        }

        .o-at {
            --at-delay-in: .3s;
        }
    }

    &:nth-child(4) .c-menu_link {

        &:before {
            content: "\2463";
        }

        .o-at {
            --at-delay-in: .35s;
        }
    }

    &:nth-child(5) .c-menu_link {

        &:before {
            content: "\2464";
        }

        .o-at {
            --at-delay-in: .4s;
        }
    }

    &:nth-child(6) .c-menu_link {

        &:before {
            content: "\2464";
        }

        .o-at {
            --at-delay-in: .45s;
        }
    }
}

.c-menu_link {
    display: flex;
    align-items: baseline;
    padding-top: .1em;
    padding-bottom: .1em;

    &.o-link:after {
        left: 2em;
    }

    &:before {
        content: "\2192";
        display: inline-block;
        width: 2em;
    }
}

.c-menu_shop {
    display: inline-block;

    .o-at {
        --at-delay-in: .5s;
    }
}

.c-menu_bottom {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    max-width: 19em;
    margin-top: vh(5);
}


</style>
