<template>
    <div
        class="o-asset"
        :class="className"
    >
        <img
            ref="asset"
            :src="src"
            :alt="alt"
            :width="width"
            :height="height"
            @loaded="loaded"
        >
    </div>
</template>

<script>

import { CUSTOM_EVENTS }            from '@/constants'

export default {
    name: 'Asset',
    emits: ['loaded'],
    data: () => ({
        src: '',
        isLoaded: false,
        isVisible: false,
    }),
    props: {
        url: {
            type: String,
            required: true
        },
        width: {
            type: Number,
            default: 1
        },
        height: {
            type: Number,
            default: 1
        },
        alt: {
            type: String,
            default: ''
        },
        radius: {
            type: Boolean,
            default: false
        },
        cover: {
            type: Boolean,
            default: false
        },
        contain: {
            type: Boolean,
            default: false
        },
        preload: {
            type: Boolean,
            default: false
        }
    },
    created() {
        this.src = `data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20${this.width}%20${this.height}'%3E%3C/svg%3E`
    },
    mounted() {

        if(!this.preload) {
            window.addEventListener(CUSTOM_EVENTS.READY, () => {
                this.setSrc()
            })
        } else {
            this.setSrc()
        }
    },
    methods: {
        setSrc() {
            this.src = this.url
        },
        loaded() {
            this.isLoaded = true
            this.$emit('loaded')
        }
    },
    computed: {
        className() {
            let classname = ''

            if(this.radius) {
                classname += ' -radius'
            }

            if(this.cover) {
                classname += ' -cover'
            }

            if(this.contain) {
                classname += ' -contain'
            }

            if(this.isVisible) {
                classname += ' is-visible'
            }

            return classname
        }
    },
}

</script>

<style lang="scss">

.o-asset {

    img {
        display: block;
        width: 100%;
        height: auto;
    }

    // Modifiers
    &.-radius {
        border-radius: var(--asset-radius, #{$border-radius});
        overflow: hidden;
    }

    &.-cover,
    &.-contain {

        img {
            height: 100%;
            object-position: 50% 50%;
        }
    }

    &.-cover img {
        object-fit: cover;
    }

    &.-contain img {
        object-fit: contain;
    }
}

</style>
