/*
** Round number with n decimals
*/

export const round = (x, n=2) => {
  const decimals = 10 ** n
  return Math.round(x * decimals)/decimals
}


/*
** Clamp number with min/max values
*/

export const clamp = (num, min, max) => Math.min(Math.max(num, min), max)
