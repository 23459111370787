<template>
    <main id="top">
        <hero class="c-404_hero">
            <template v-slot:title>
                <rolling-text
                    :visible="true"
                    v-bind="title1"
                />
                <rolling-text
                    :visible="true"
                    v-bind="title2"
                />
            </template>
            <template v-slot:sub>
                <app-button
                    href="/"
                    label="Back to homepage"
                />
            </template>
        </hero>
    </main>
</template>

<script>

import RollingText                      from '@/templates/objects/RollingText'
import AppButton                        from '@/templates/components/AppButton'
import Hero                             from '@/templates/sections/Hero'

import { FONT_FAMILIES }                from '@/constants'

export default {
    name: 'Error404',
    components: {
        RollingText,
        AppButton,
        Hero,
    },
    data: () => ({
        title1: {
            text: 'Page',
            fonts: [ FONT_FAMILIES.SANS.KEY, FONT_FAMILIES.SANS.KEY ],
            defaultFont: FONT_FAMILIES.SERIF.KEY,
            durationRange: [1.75, 2.5]
        },
        title2: {
            text: 'Not found',
            fonts: [ FONT_FAMILIES.SANS.KEY, FONT_FAMILIES.SANS.KEY , FONT_FAMILIES.SANS.KEY ],
            defaultFont: FONT_FAMILIES.SERIF.KEY,
            durationRange: [1.75, 2.5]
        },
    })
}

</script>

<style lang="scss">

.c-404_hero {

    .s-hero_title {

        &:after,
        &:before {
            display: none;
        }
    }

    .s-hero_sub {
        justify-content: center;
        margin-top: rem(20px);
        transition: opacity $speed-slow $easing 2s;

        html:not(.is-ready) & {
            opacity: 0;
        }
    }
}

</style>
