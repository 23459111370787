<template>
    <component
        v-if="text"
        :is="tag"
        :class="className"
        :style="styles"
        :aria-label="text"
        v-inview.once="inview"
    >
        <span
            ref="inner"
            v-html="text"
            aria-hidden="true"
            class="o-at_inner"
        ></span>
    </component>
</template>

<script>

import { CUSTOM_EVENTS, PRERENDERING }  from '@/constants'
import { SplitText }                    from '@/gsap'

const CLASSNAME = 'o-at'

export default {
    name: 'AnimText',
    props: {
        tag: {
            type: String,
            default: 'p'
        },
        text: {
            type: String,
            required: true
        },
        type: {
            type: String,
            default: 'lines',
            // The value must be in the following array
            validator(value) {
                return ['lines', 'chars'].includes(value)
            }
        },
        visible: {
            type: Boolean,
            default: false
        },
        reveal: {
            type: Boolean,
            default: false
        },
    },
    data: () => ({
        styles: false,
        isVisible: false,
        inview: false
    }),
    created() {

        if(PRERENDERING) {
            return
        }

        this.isVisible = this.visible

        this.inview = this.reveal ? {
            start: 'top+=25% bottom',
            end: 'bottom top',
            onEnter: () => {
                this.show()
            },
            onEnterBack: () => {
                this.show()
            },
            onLeave: () => {
                this.hide()
            },
        } : false

    },
    mounted() {

        if(PRERENDERING) {
            return
        }

        window.addEventListener(CUSTOM_EVENTS.FONTS_LOADED, () => {

            this.initSplits()

            // Window event
            window.addEventListener(CUSTOM_EVENTS.RESIZE_END, this.onResize = () => this.resize())
        })
    },
    computed: {
        lines() {
            return this.type === 'lines'
        },
        chars() {
            return this.type === 'chars'
        },
        splitType() {
            return this.lines ? 'lines, words' : 'lines, chars'
        },
        className() {
            let classname = `${CLASSNAME} -${this.type}`

            if(this.isVisible) {
                classname += ' is-inview'
            }

            return classname
        }
    },
    methods: {
        initSplits() {
            // Split text
            this.split = new SplitText(this.$refs.inner, {
                type: this.splitType,
                tag: 'span',
                linesClass: `${CLASSNAME}_line`,
                wordsClass: `${CLASSNAME}_word`,
                charsClass: `${CLASSNAME}_char`,
            })

            // Add number of lines props to container
            const totalLines = this.split.lines.length
            this.styles = `--at-lines-count: ${totalLines};`

            // Add line index to each lines
            this.split.lines.forEach(($line, i) => {
                $line.style.setProperty('--at-lines-index', i)
            })

            // Add chars index to each char
            const totalChars = this.split.chars.length
            if(totalChars > 0) {
                this.styles += ` --at-chars-count: ${totalChars};`
                this.split.chars.forEach(($char, i) => {
                    $char.style.setProperty('--at-chars-index', i)
                })
            }
        },

        revertSplits() {
            this.split.revert()
        },

        resize() {
            this.revertSplits()

            this.$nextTick(() => {
                this.initSplits()
            })
        },

        show() {
            this.isVisible = true
        },

        hide() {
            this.isVisible = false
        }
    },
    watch: {
        visible(visible) {
            if(visible) {
                this.show()
            } else {
                this.hide()
            }
        }
    },
}

</script>

<style lang="scss">


/*----------  Mixins  ----------*/


@mixin at-show {

    &.-lines .o-at_word,
    &.-chars .o-at_char {
        transform: translate(0);

        transition-timing-function: var(--at-easing-in);
        transition-duration: var(--at-duration-in);
    }

    &.-lines .o-at_word {
        transition-delay: calc(var(--at-duration-in)/2 * (var(--at-lines-index)/var(--at-lines-count)) + var(--at-delay-in));
    }

    &.-chars .o-at_char {
        transition-delay: calc(var(--at-duration-in)/2 * (var(--at-chars-index)/var(--at-chars-count)) + var(--at-delay-in));
    }
}

@mixin at-hide {

    &.-lines .o-at_word,
    &.-chars .o-at_char {
        transform: translate(0, 115%);
        will-change: transform;

        transition-property: transform;
        transition-duration: var(--at-duration-out);
        transition-timing-function: var(--at-easing-out);
        transition-delay: calc(var(--at-duration-out)/2 * ((var(--at-lines-count) - var(--at-lines-index))/var(--at-lines-count)) + var(--at-delay-out));
    }

    &.-lines .o-at_word {
        transition-delay: calc(var(--at-duration-out)/2 * ((var(--at-lines-count) - var(--at-lines-index))/var(--at-lines-count)) + var(--at-delay-out));
    }

    &.-chars .o-at_char {
        transition-delay: calc(var(--at-duration-out)/2 * ((var(--at-chars-count) - var(--at-chars-index))/var(--at-chars-count)) + var(--at-delay-out));
    }
}

/*----------  Styles  ----------*/

.o-at {
    --at-lines-count: 1;
    --at-lines-index: 0;
    --at-chars-count: 1;
    --at-chars-index: 0;

    --at-duration-in: .8s;
    --at-easing-in: #{$easing};
    --at-delay-in: 0s;

    --at-duration-out: calc(.5 * var(--at-duration-in));
    --at-easing-out: #{$easing};
    --at-delay-out: 0s;

    display: block;

    @include at-hide;

    html.is-ready &.is-inview,
    html.is-ready .is-inview & {
        @include at-show;
    }
}

.o-at_inner {
    display: block;
    width: 100%;
}

.o-at_line {

    @supports (clip-path: polygon(0 0, 100% 0, 100% 115%, 0 115%)) {
        clip-path: polygon(0 0, 100% 0, 100% 115%, 0 115%)
    }

    @supports not (clip-path: polygon(0 0, 100% 0, 100% 115%, 0 115%)) {
        overflow: hidden;
    }
}

.o-at_word {
}

.o-at_char {
    display: inline-block !important;
}

@keyframes at-roll {
    0% {
        transform: translate(0);
    }
    40% {
        transform: translate(0, -115%);
    }
    40.001% {
        transform: translate(0, 115%);
    }
    100% {
        transform: translate(0);
    }
}

@keyframes at-roll-back {
    to {
        transform: translate(0);
    }
}

</style>
