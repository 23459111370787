<template>
    <section class="s-glyph">
        <div class="s-glyph_dropdown">
            <dropdown
                :options="fonts"
                :boxed="true"
                :initialValue="3"
                @change="updateFont"
            />
        </div>
        <div class="s-glyph_inner | o-container">
            <div class="s-glyph_content">
                <anim-text
                    class="s-glyph_title | c-heading -h2 | u-hide@to-small"
                    text="Pangram Pangram® Foundry offers you an elegant & retro contemporary new font — Fragment"
                    tag="h2"
                    :reveal="true"
                />
                <div class="s-glyph_list">
                    <div
                        v-for="(g, i) in glyphs"
                        :key="`glyph-${i}`"
                        class="s-glyph_item"
                    >
                        <span
                            class="s-glyph_button"
                            :class="{ 'is-active' : glyph === g }"
                            @mouseenter="setGlyph(g)"
                            @click="setGlyph(g)"
                            v-html="g"
                        ></span>
                    </div>
                </div>
            </div>
            <div class="s-glyph_preview | u-hide@to-small">
                <span>{{ glyph }}</span>
            </div>
        </div>
    </section>
</template>

<script>

import AnimText                 from '@/templates/objects/AnimText'
import Dropdown                 from '@/templates/objects/Dropdown'
import { glyphs }               from '@/data'
import { FONT_FAMILIES_ARRAY }  from '@/constants'

const FONT_WEIGHTS = {
    100: 'Thin',
    200: 'Extra Light',
    300: 'Light',
    400: 'Regular',
    500: 'Medium',
    600: 'Semi Bold',
    700: 'Bold',
    800: 'Extra Bold',
    900: 'Black',
}

export default {
    name: 'GlyphViewer',
    components: {
        AnimText,
        Dropdown,
    },
    data: () => ({
        // fonts,
        glyphs,
        glyph: null,
        fontFamily: 'inherit',
        fontWeight: 'inherit',
    }),
    created() {
        // Set first glyph to be F
        this.setGlyph('F')
    },
    computed: {
        fonts() {
            const fonts = []
            let weight
            FONT_FAMILIES_ARRAY.forEach(f => {
                for (weight = f.WEIGHT_RANGE[0]; weight < f.WEIGHT_RANGE[1]; weight += 100) {
                    fonts.push({
                        ...f,
                        NAME: `${f.NAME} ${FONT_WEIGHTS[weight]}`,
                        weight
                    })
                }
            })

            return fonts
        }
    },
    methods: {
        setGlyph(glyph) {
            this.glyph = glyph
        },
        updateFont(e) {
            this.fontFamily = e.CSS_VAR
            this.fontWeight = e.weight
        }
    }
}

</script>

<style lang="scss">

.s-glyph {
    --glyph-ff: v-bind(fontFamily);
    --glyph-fw: v-bind(fontWeight);

    &:after {
        content: "";
        z-index: z("above");
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: currentColor;
    }
}

.s-glyph_dropdown {
    z-index: z("above");
    position: sticky;
    margin-left: auto;
    background-color: $color-darkest;

    // &:before {
    //     content: "";
    //     position: absolute;
    //     bottom: 100%;
    //     left: 0;
    //     width: 100%;
    //     height: var(--header-height);
    //     background-color: $color-darkest;
    // }

    @media (max-width: $to-small) {
        top: calc(var(--header-height) + 1px);
        width: 100vw;
    }

    @media (min-width: $from-small) {
        top: var(--header-height);
        grid-column: span 2;
        width: var(--container-width);
        padding-right: grid-space(4/8, 2);

        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: currentColor;
        }
    }
}

.s-glyph_inner {
    display: grid;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);

    @media (min-width: $from-small) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-column-gap: var(--grid-gutter);
    }
}

.s-glyph_content {

    @media (min-width: $from-small) {
        grid-column-start: 1;
        border-right: 1px solid currentColor;
    }
}

.s-glyph_title {
    padding-top: 2em;
    padding-bottom: 2em;

    @media (min-width: $from-medium) {
        width: grid-space(3/8);
    }
}

.s-glyph_list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(rem(48px), 1fr));
    padding-top: var(--grid-gutter);
    padding-bottom: var(--grid-gutter);
    padding-right: var(--grid-gutter-half);
    font-family: var(--glyph-ff, inherit);
    font-variation-settings: "wght" var(--glyph-fw, inherit);
    font-size: rem(24px);
}

.s-glyph_item {
    height: 0;
    padding-top: 100%;
}

.s-glyph_button {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;

    @media (min-width: $from-small) {

        &.is-active {
            color: var(--color-bg);
            background-color: var(--color-text);
        }
    }
}

.s-glyph_preview {
    font-size: 35vw;
    font-family: var(--glyph-ff, inherit);
    font-variation-settings: "wght" var(--glyph-fw, inherit);

    span {
        position: sticky;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: vh(100);
        padding-top: rem(44px);
        text-align: center;
        overflow: hidden;
    }
}

</style>
