import { CUSTOM_EVENTS } from '@/constants'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SplitText } from 'gsap/SplitText'

gsap.registerPlugin(ScrollTrigger, SplitText)

export * from 'gsap'
export { ScrollTrigger, SplitText }

window.addEventListener(CUSTOM_EVENTS.BODY_RESIZE, ScrollTrigger.refresh)
