<template>
    <div class="s-credits | o-container">
        <anim-text
            class="s-credits_title | c-heading -h3"
            text="Credits"
            tag="h3"
            :reveal="true"
        />
        <div class="s-credits_list">
            <div class="s-credits_col -large">
                <anim-text
                    class="s-credits_label | o-text -sans"
                    text="Typeface"
                    tag="h4"
                    :reveal="true"
                />
                <anim-text
                    text="PP Fragment is born from vintage lettering and signs, bridging 19th-century letterforms and contemporary typography ● Pangram Pangram’s 35th release uses the latest technology, gracefully oscillating between an elegant, highly contrasted Serif face, a revival mid-serif called Glare, and a strong Sans, each with distinct features yet perfectly complementary."
                    tag="p"
                    :reveal="true"
                />
                <anim-text
                    text="It comes in 4 preset cuts, Sans, Serif, Glare, and Text, each with unique personalities and quirks. Each weight counts 581 glyphs with plenty of alternate symbols to achieve the best-desired result for your next design."
                    tag="p"
                    :reveal="true"
                />
                <anim-text
                    text="Its power and versatility also comes from its 32 very distinct and unique weights! These weights were carefully crafted and cut for maximum breath of ability ▲ PP Fragment doesn’t disappoint and will surely be one of your best tools in your next design endeavour."
                    tag="p"
                    :reveal="true"
                />
            </div>
            <div class="s-credits_col">
                <anim-text
                    class="s-credits_label | o-text -sans"
                    text="Visuals"
                    tag="h4"
                    :reveal="true"
                />
                <anim-text
                    text="This (awesome) website—courtesy of Locomotive. Our philosophy is built on people who love creating, learning and growing together. A team with ties that transcend the workplace."
                    tag="p"
                    :reveal="true"
                />
                <br>
                <anim-text
                    text='Visual Artist <a href="https://www.instagram.com/barthur__/" target="_blank" ref="noopener noreferrer" class="o-link">@barthur</a>'
                    tag="p"
                    :reveal="true"
                />
            </div>
        </div>
    </div>
</template>

<script>

import AnimText                 from '@/templates/objects/AnimText'

export default {
    name: 'Credits',
    components: {
        AnimText,
    },
}

</script>

<style lang="scss">


.s-credits {
    z-index: z("above");
    padding-top: vh(5);
    padding-bottom: vh(15);
}

.s-credits_title {
    margin-bottom: vh(10);
}

.s-credits_list {
    display: grid;
    grid-gap: vh(5) var(--grid-gutter);

    @media (min-width: $from-small) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (min-width: $from-medium) {
        grid-template-columns: grid-space(6/8) grid-space(2/8)
    }
}

.s-credits_col {

    @media (max-width: $to-medium) {

        p:not(:first-child) {
            margin-top: 1.5em;
        }
    }

    @media (min-width: $from-tiny) and (max-width: $to-small) {

        p {
            padding-right: grid-space(1/8, 1);
        }
    }

    @media (min-width: $from-medium) {

        &.-large {
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            grid-column-gap: var(--grid-gutter);

            .s-credits_label {
                grid-column: 1/4;
            }
        }
    }

    @media (min-width: $from-medium) {

        p {
            padding-right: 15%;
        }
    }
}

.s-credits_label {
    width: 100%;
    margin-bottom: 1em;
    padding-bottom: 1em;

    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 1px;
        background-color: currentColor;
        transform: scale(0, 1);
        transform-origin: 0 50%;
    }

    &.is-inview:after {
        transform: scale(1);
        transition: transform $speed-slow $ease-sine-in-out;
    }
}
</style>
