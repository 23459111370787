<template>
   <div class="s-posters | o-container o-sticky-section -desktop | u-color-invert" v-inview="{ onToggle }">
        <div class="s-posters_wrap">
            <div class="s-posters_inner">
                <asset
                    v-for="bg in bgCount"
                    :key="`poster-bg-${bg}`"
                    :url="`${path}bg-${bg}.png`"
                    :cover="true"
                    :radius="true"
                    class="s-posters_bg"
                    :class="{ 'is-visible' : activeBg === bg }"
                />
                <asset
                    v-for="poster in postersCount"
                    :key="`poster-p-${poster}`"
                    :url="`${path}poster-${poster}.png`"
                    :width="posterSize.width"
                    :height="posterSize.height"
                    :contain="true"
                    class="s-posters_poster"
                    :class="{ 'is-visible' : activePoster === poster }"
                />
            </div>
            <app-button
                label="randomize"
                tag="button"
                class="s-posters_button"
                @click="randomize()"
            />
        </div>
   </div>
</template>

<script>

import Asset                        from '@/templates/objects/Asset'
import AppButton                    from '@/templates/components/AppButton'
import { posters }                  from '@/data'

export default {
    name: 'Posters',
    components: {
        Asset,
        AppButton
    },
    data: () => ({
        path: posters.path,
        postersCount: posters.postersCount,
        bgCount: posters.bgCount,
        posterSize: posters.size,
        activePoster: 0,
        activeBg: 0
    }),
    methods: {
        onToggle({isActive}) {
            if(isActive) {
                this.randomize()
            } else {
                clearInterval(this.countdown)
            }
        },
        randomize() {
            this.setCountdown()
            this.activePoster = this.activePoster + 1 > this.postersCount ? 1 : this.activePoster + 1
            this.activeBg = this.activeBg + 1 > this.bgCount ? 1 : this.activeBg + 1
        },
        setCountdown() {
            if(this.countdown) {
                clearInterval(this.countdown)
            }

            this.countdown = setInterval(() => {
                this.randomize()
                clearInterval(this.countdown)
                this.setCountdown()
            }, 5000);
        }
    }
}

</script>

<style lang="scss">

.s-posters {
    --sticky-section-height: #{vh(200)};

    margin-top: vh(20);
    text-align: center;
}

.s-posters_wrap {
    display: flex;
    align-items: center;
    justify-content: center;

    html.is-mobile & {
        height: var(--height-gutters);
    }
}

.s-posters_inner {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.s-posters_bg {
    --asset-radius: #{3 * $border-radius};
    --poster-anim-in-duration: .8s;
    --poster-anim-out-duration: .4s;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    will-change: transform;
    animation: anim-posters-bg-out .4s $ease-power2-in .2s forwards;

    &.is-visible {
        transform: translate(0, 100%);
        animation: anim-posters-bg-in .7s $ease-power2-in-out .3s forwards;
    }
}

.s-posters_poster {
    --poster-width: #{grid-space(6/8)};
    --poster-height: #{vh(60)};
    --poster-anim-in-duration: 1s;
    --poster-anim-out-duration: .6s;

    z-index: 1;
    position: absolute;
    top: calc(50% - 0.5 * var(--poster-height));
    left: calc(50% - 0.5 * var(--poster-width));
    width: var(--poster-width);
    height: var(--poster-height);
    will-change: transform;
    animation: anim-posters-poster-in 1s $ease-power2-in-out forwards;

    &:not(.is-visible) {
        animation: anim-posters-poster-out .4s $ease-power2-in forwards;
    }

    &.is-visible {
        z-index: 0;
    }

    @media (min-width: $from-tiny) and (max-width: $to-small) {
        --poster-width: #{grid-space(4/8)};
    }

    @media (min-width: $from-small) {
        --poster-width: #{grid-space(3/8)};
    }
}

.s-posters_button {
    z-index: z("above");
    position: absolute;
    left: 50%;
    // bottom: calc(#{vh(10)} - 0.5 * var(--header-height));
    bottom: calc( 0.5 * var(--header-height));
    transform: translate(-50%, 0);
}


// Animations
@keyframes anim-posters-poster-in {
    from {
        transform: scale(.8);
    }
}

@keyframes anim-posters-poster-out {
    to {
        // opacity: 0;
        transform: translate(0, calc(-50% - #{vh(50)}));
    }
}

@keyframes anim-posters-bg-in {
    from {
        opacity: .75;
        transform: translate(0, 91%) scale(.8);
    }
    to {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes anim-posters-bg-out {
    to {
        opacity: .75;
        transform: translate(0, -91%) scale(.8);
    }
}

</style>
