<template>
    <div
        class="o-dropdown"
        :class="[
            { '-boxed' : boxed },
            { 'is-open' : isOpen }
        ]"
    >
        <button
            class="o-dropdown_button -current"
            @click="toggle()"
        >
            {{ value.NAME }}
        </button>
        <div
            class="o-dropdown_list"
            @wheel.passive="e => e.stopPropagation()"
        >
            <button
                v-for="(option, i) in options"
                :key="`dropdown-option-${i}`"
                class="o-dropdown_button"
                @click="updateValue(option)"
            >
                {{ option.NAME }}
            </button>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Dropdown',
    emits: ['change'],
    data: () => ({
        value: null,
        isOpen: false,
    }),
    props: {
        options: {
            type: Array,
            required: true
        },
        boxed: {
            type: Boolean,
            default: false
        },
        initialValue: {
            type: Number,
            default: 0
        }
    },
    created() {
        this.updateValue(this.options[this.initialValue])
    },
    methods: {
        toggle() {
            if(this.isOpen) {
                this.close()
            } else {
                this.open()
            }
        },
        open() {
            this.isOpen = true

            document.addEventListener('click', this.onClickOutside = e => {
                if (!this.$el.contains(e.target)) {
                    this.close()
                }
            })
        },
        close() {
            this.isOpen = false
            document.removeEventListener('click', this.onClickOutside)
        },
        updateValue(value) {
            this.value = value
            this.$emit('change', value)
            this.close()
        },
    },
}

</script>

<style lang="scss">

.o-dropdown {
    z-index: z("dropdown");
    font-size: rem(14px);

    &.is-open {}
}

.o-dropdown_list {
    position: absolute;
    top: calc(100% - 1px);
    left: 0;
    display: none;
    flex-direction: column;
    width: 100%;
    max-height: rem(330px);
    display: none;
    background-color: var(--color-bg);
    overflow-y: auto;

    // &::-webkit-scrollbar {
    //     width: 2px;
    //     background: transparent;
    // }
    // &::-webkit-scrollbar-thumb {
    //     background-color: rgba($color-lightest, 1);
    //     // border: solid whiteSmoke 2px;
    // }
    // &::-webkit-scrollbar-thumb:hover {
    //     background-color: rgba($color-lightest, .6);
    // }

    .o-dropdown.is-open & {
        display: flex;
    }
}

.o-dropdown_button {
    display: flex;
    align-items: center;
    margin-top: -1px;
    padding: rem(12px) rem(20px);
    text-align: left;
    color: var(--color-text);
    border: 1px solid var(--color-text);

    // &:not(.-current):hover {
    //     z-index: z("above");
    //     --color-text: #{$color-medium};
    // }

    .o-dropdown.-boxed &.-current:after {
        right: rem(20px);
    }

    .o-dropdown:not(.-boxed) &.-current {
        padding-right: 0;
        padding-left: 0;
        border-top: 0;
        border-right: 0;
        border-left: 0;
    }

    &.-current {
        z-index: z("above");
        width: 100%;

        &:after {
            content: "\2193";
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            height: rem(44px);
            font-family: var(--font-family-sans);
            font-variation-settings: "wght" 600;
            transition: transform $speed $easing;
        }
    }

    .o-dropdown.is-open & {

        &.-current {

            &:after {
                transform: rotate(180deg);
            }
        }
    }

    .o-dropdown.-boxed.is-open & {

        &:hover,
        &.-current {
            color: var(--color-bg);
            background-color: var(--color-text);
        }
    }
}

</style>
