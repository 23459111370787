<template>
    <section class="s-intro | o-container">
        <header class="s-intro_header">
            <anim-text
                class="s-intro_col | c-heading -h2"
                text="PP Fragment is born from vintage lettering and signs, bridging 19th-century letterforms and contemporary typography ✳ Pangram Pangram’s 35th release uses the latest technology."
                tag="p"
                :reveal="true"
            />
            <anim-text
                class="s-intro_col | c-heading -h2"
                text="Gracefully oscillating between an elegant, highly contrasted Serif face, a revival mid-serif called Glare, and a strong Sans, each with distinct features yet perfectly complementary."
                tag="p"
                :reveal="true"
            />
        </header>
        <div class="s-intro_inner">
            <div class="s-intro_list">
                <asset
                    url="/assets/images/layout/intro.jpg"
                    alt="Landscape with lines curves"
                    :width="1024"
                    :height="1196"
                    :radius="true"
                    class="s-intro_asset"
                    v-inview.once
                />
                <dl class="o-dl" v-inview>
                    <div
                        v-for="(item, i) in details"
                        :key="`dl-${i}`"
                        class="o-dl_row"
                    >
                        <anim-text
                            class="o-dl_label"
                            tag="dt"
                            :text="item.label"
                        />
                        <anim-text
                            class="o-dl_desc"
                            tag="dd"
                            :text="item.desc"
                        />
                    </div>
                </dl>
            </div>
            <div class="s-intro_content">
                <anim-text
                    class="s-intro_col | o-text -text"
                    text="It comes in 4 preset cuts, Sans, Serif, Glare, and Text, each with unique personalities and quirks. Each weight counts 581 glyphs with plenty of alternate symbols to achieve the best-desired result for your next design."
                    tag="p"
                    :reveal="true"
                />
                <anim-text
                    class="s-intro_col | o-text -text"
                    text="Its power and versatility also comes from its 32 very distinct and unique weights! These weights were carefully crafted and cut for maximum breath of ability ▲ PP Fragment doesn’t disappoint and will surely be one of your best tools in your next design endeavour."
                    tag="p"
                    :reveal="true"
                />
                <div class="s-intro_col -large">
                    <app-button
                        href="https://pangrampangram.com/blogs/journal/fragment"
                        target="_blank"
                        rel="noopener noreferrer"
                        :border="true"
                        label="Read the interview on PP® Foundry"
                    />
                </div>
            </div>
        </div>
    </section>
</template>

<script>

import AnimText                 from '@/templates/objects/AnimText'
import Asset                    from '@/templates/objects/Asset'
import AppButton                from '@/templates/components/AppButton'
import { details }              from '@/data'

export default {
    name: 'Intro',
    components: {
        AnimText,
        Asset,
        AppButton
    },
    data: () => ({
        details
    })
}

</script>

<style lang="scss">

.s-intro {
    padding-top: vh(25);

    @media (max-width: $to-small) {
    }

    @media (min-width: $from-small) {
    }
}

.s-intro_header {

    @media (max-width: $to-small) {
    }
}

.s-intro_inner {

    @media (max-width: $to-medium) {
        padding-top: vh(10);
    }

    @media (min-width: $from-medium) {
        display: flex;
    }
}

.s-intro_content {

    @media (max-width: $to-medium) {
        padding-top: vh(10);
    }

    @media (min-width: $from-small) {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-gap: rem(40px) var(--grid-gutter);

        .s-intro_col {
            padding-right: 25%;
        }
    }

    @media (min-width: $from-medium) {
        width: grid-space(4/8);
        padding-top: 17vw;

        .s-intro_col {
            padding-right: 10%;
        }
    }

    @media (min-width: $from-large) {

        .s-intro_col {
            padding-right: 25%;
        }
    }
}

.s-intro_col {

    &.-large {
        grid-column: span 2;
    }

    @media (max-width: $to-small) {
        width: grid-space(6/8);

        &:nth-child(even) {
            margin-left: auto;
        }

        &:not(:first-child) {
            margin-top: vh(10);
        }
    }

    @media (min-width: $from-small) {

        .s-intro_header & {
            width: grid-space(3/8);

            &:nth-child(2) {
                margin-left: grid-space(4/8, 1);
            }
        }
    }
}

.s-intro_list {

    @media (min-width: $from-medium) {
        width: grid-space(3/8);
        margin-right: grid-space(1/8, 2);
    }
}

.s-intro_asset {
    margin-bottom: var(--grid-gutter);
    opacity: 0;
    transform: translate(0, 25%);

    &.is-inview {
        opacity: 1;
        transform: translate(0);
        transition: opacity $speed-slow $easing, transform $speed-slow $easing;
    }

    @media (max-width: $to-medium) {
        width: grid-space(6/8);
        margin-right: auto;
        margin-left: auto;
    }
}


// Description list
.o-dl {}

.o-dl_row {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: var(--grid-gutter);
    padding-top: .5em;
    padding-bottom: .5em;

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 1px;
        background-color: currentColor;
        transform: scale(0, 1);
        transform-origin: 0 50%;
    }

    @for $i from 1 through 5 {
        $delay: #{($i - 1)/10}s;

        .o-dl.is-inview &:nth-child(#{$i}) {

            .o-at {
                --at-delay-in: #{$delay};
            }

            &:after {
                transition: transform $speed-slow $ease-sine-in-out $delay;
            }
        }
    }

    .o-dl.is-inview &:after {
        transform: scale(1);
    }
}

.o-dl_label {}

.o-dl_desc {}


</style>
