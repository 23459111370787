<template>
    <span
        class="o-rolling-text"
        :class="{ 'is-visible' : visible }"
        :aria-label="text"
    >
        <template
            v-for="(item, i) in items"
            :key="`rolling-text-${i}-${item.l}`"
        >
            <span
                v-if="item === ' '"
                class="o-rolling-text_spacer"
                aria-hidden="true"
            ></span>
            <span
                v-else
                class="o-rolling-text_item"
                :style="`--rolling-text-duration: ${item.d}s`"
                aria-hidden="true"
            >
                <span
                    class="o-rolling-text_proxy"
                    :style="`font-family: ${item.font};`"
                >
                    {{ item.l }}
                </span>
                <span class="o-rolling-text_inner">
                    <span
                        v-for="(f, j) in item.fonts"
                        :key="`rolling-text-${i}-${item.l}-${j}`"
                        :style="`font-family: ${f}`"
                        class="o-rolling-text_letter"
                    >
                        {{ item.l }}
                    </span>
                </span>
            </span>
        </template>
    </span>
</template>

<script>

import { FONT_FAMILIES, FONT_FAMILIES_KEYS }    from '@/constants'
import { round }                                from '@/utils/maths'

export default {
    name: 'RollingText',
    props: {
        text: {
            type: String,
            required: true,
            // // The value must match a font family
            // validator(value) {
            //     return FONT_FAMILIES_KEYS.includes(value)
            // }
        },
        fonts: {
            type: Array,
            default: () => []
        },
        defaultFont: {
            type: String,
            default: FONT_FAMILIES_KEYS.SANS
        },
        visible: {
            type: Boolean,
            default: false
        },
        durationRange: {
            type: Array,
            default: () => [1.3, 2.5],
            // // The value must be an array of two numbers
            // validator(value) {
            //     return value.length === 2 && typeof value[0] === Number && typeof value[1] === Number
            // }
        }
    },
    computed: {
        items() {

            // Format array of letters
            let font, fonts
            const items = []

            this.text.split('').forEach((t, i) => {

                // Spacer
                if(t === ' ') {
                    return items.push(t)
                } else {

                    font = this.fonts[i] || this.defaultFont
                    fonts = this._shuffleArray(FONT_FAMILIES_KEYS.filter(k => k !== font))
                    fonts = fonts.map(f => FONT_FAMILIES[f].CSS_VAR)
                    font = FONT_FAMILIES[font].CSS_VAR
                    fonts.unshift(font)

                    items.push({
                        l: t,
                        d: this._random(this.durationRange[0], this.durationRange[1]),
                        font,
                        fonts,
                    })
                }
            })

            return items
        }
    },
    methods: {
        _shuffleArray(array) {
            return array.sort(() => 0.5 - Math.random())
        },
        _random(min, max) {
            return round(Math.random() * (max - min) + min, 2)
        }
    }
}

</script>

<style lang="scss">

.o-rolling-text {
    display: block;
}

.o-rolling-text_spacer {
    display: inline-block;
    width: .35em;
}

.o-rolling-text_item {
    display: inline-flex;

    @supports (clip-path: polygon(-50% 0, 150% 0, 150% 100%, -50% 100%)) {
        clip-path: polygon(-50% 0, 150% 0, 150% 100%, -50% 100%)
    }

    @supports not (clip-path: polygon(-50% 0, 150% 0, 150% 100%, -50% 100%)) {
        letter-spacing: 0;
        overflow: hidden;
    }
}

.o-rolling-text_proxy {
    opacity: 0;
    pointer-events: none;
}

.o-rolling-text_inner {
    position: absolute;
    left: 0;
    bottom: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;

    html.is-ready & {
        animation: anim-rolling-text-out calc(0.25 * var(--rolling-text-duration, 1.5s)) $ease-power2-in forwards;
    }

    html.is-ready .o-rolling-text.is-visible & {
        animation: anim-rolling-text-in var(--rolling-text-duration, 1.5s) $ease-power2-in-out var(--rolling-text-delay, 0s) forwards;
    }
}

.o-rolling-text_letter {}


// Animations
@keyframes anim-rolling-text-in {
    from {
        transform: translate(0);
    }
    to {
        transform: translate(0, 100%);
    }
}

@keyframes anim-rolling-text-out {
    from {
        transform: translate(0, 100%);
    }
    to {
        transform: translate(0, 125%);
    }
}

</style>
