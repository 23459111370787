<template>
    <footer class="c-footer | o-container">
        <div ref="bottom" class="c-footer_bottom">
            <span ref="glyph" class="c-footer_glyph">&Omega;</span>
            <div class="c-footer_content">
                <anim-text
                    text="Copyright &copy; 2022 <br class='u-hide@from-medium'>Pangram Pangram Foundry"
                    tag="p"
                    :reveal="true"
                />
                <anim-text
                    text="Website by <a href='https://locomotive.ca/en' target='_blank' rel='noopener noreferrer'>Locomotive</a>"
                    tag="p"
                    :reveal="true"
                />
            </div>
        </div>
    </footer>
</template>

<script>

import AnimText                 from '@/templates/objects/AnimText'
import { gsap }                 from '@/gsap'

export default {
    name: 'AppFooter',
    components: {
        AnimText
    },
    data: () => ({
        fontWeight: 700
    }),
    mounted() {

        // gsap.set(this.$refs.glyph, {
        //     fontSize: 1.37 * window.innerWidth
        // })

        gsap.from(this.$refs.glyph, {
            scrollTrigger: {
                trigger: this.$refs.bottom,
                start: 'top bottom',
                end: 'bottom bottom',
                scrub: true,
                onUpdate: ({progress}) => {
                    this.fontWeight = 700 - progress * 600
                }
            },
            yPercent: 15,
            scale: 0,
            ease: 'power2.out'
        })
    }
}

</script>

<style lang="scss">

.c-footer {
    width: 100%;
    overflow: hidden;
}

.c-footer_glyph {
    left: -50%;
    display: block;
    width: 200%;
    height: 1.6em;
    font-family: var(--font-family-serif);
    font-size: calc(0.5 * 132vw);
    font-variation-settings: "wght" v-bind(fontWeight);
    line-height: .8;
    text-align: center;
    transform-origin: 50% 0;
    transform: scale(2);
}

.c-footer_content {
    position: absolute;
    top: 50vw;
    left: 0;
    width: 100%;
    text-align: center;

    @media (min-width: $from-medium) {

        br {
            display: none;
        }
    }
}


</style>
