<template>
    <div
        class="o-grid-debugger | o-container"
        :class="{ 'is-visible' : isVisible }"
    >
        <span
            v-for="col in columns"
            :key="`grid-debugger-col-${col}`"
        ></span>
    </div>
</template>

<script>

export default {
    name: 'GridDebugger',
    data: () => ({
        isVisible: false,
        columns: 8,
    }),
    mounted() {
        let ctrlDown = false

        document.addEventListener('keydown', (e) => {
            if(e.key == 'Control') {
                ctrlDown = true;
            } else {
                if(ctrlDown && e.key == 'g') {
                    this.isVisible = !this.isVisible
                }
            }
        })

        document.addEventListener('keyup', (e) => {
            if(e.key == 'Control') {
                ctrlDown = false
            }
        })
    }
}

</script>

<style lang="scss">

.o-grid-debugger {
    z-index: 1000;
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    width: var(--container-width);
    height: 100%;
    column-gap: var(--grid-gutter);
    padding-right: var(--grid-gutter);
    padding-left: var(--grid-gutter);
    pointer-events: none;

    span {
        flex: 1 1 0;
        background-color: rgba(#00DA00, .10);
    }

    &:not(.is-visible) {
        visibility: hidden;
    }
}

</style>
